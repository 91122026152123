import React, { useEffect, useState } from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const withAuthentication = (Component) => {
  const WithAuthentication = ({firebase, ...props}) => {
    const [authUser, setAuthUser] = useState(JSON.parse(localStorage.getItem('authUser')));
    useEffect(() => {
      const firebaseListener = firebase.onAuthUserListener((au) => {
        localStorage.setItem('authUser', JSON.stringify(au));
        setAuthUser(au);
      }, () => {
        localStorage.removeItem('authUser');
        setAuthUser(null);
      })

      return firebaseListener;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <AuthUserContext.Provider value={authUser}>
        <Component {...props} />
      </AuthUserContext.Provider>
    )

  }
  return withFirebase(WithAuthentication);
};

export default withAuthentication;
