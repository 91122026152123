import React, { Component } from 'react';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import { AuthUserContext, withAuthentication } from '../Session';

const INITIAL_STATE = {
  name: '',
  slug: '',
  description: '',
  color: '',
  icon: '',
  requiresApproval: false,
  deductedLeave: false,
  deductedEgenmelding: false,
  deductedOmsorgsdager: false,
  isSickness: false,
};

class AddLeaveTypeFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onSubmit = (event) => {
    const {
      name,
      description,
      icon,
      requiresApproval,
      deductedLeave,
      deductedEgenmelding,
      deductedOmsorgsdager,
      isSickness,
      color,
    } = this.state;

    this.props.firebase
      .leaveTypeAdd({
        name,
        description,
        icon,
        requiresApproval,
        deductedLeave,
        deductedEgenmelding,
        deductedOmsorgsdager,
        isSickness,
        color,
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onCheckboxChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
    // console.log(event.target.name, event.target.checked);
  };

  render() {
    const {
      name,
      description,
      color,
      icon,
      requiresApproval,
      deductedLeave,
      deductedEgenmelding,
      deductedOmsorgsdager,
      isSickness,
    } = this.state;

    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div>
            <>
              <h4>Ny Fraværstype</h4>
              <form className="form" onSubmit={this.onSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    placeholder="Tittel..."
                    className="form-control mb-2 mr-sm-2 col-md-6"
                    value={name}
                    onChange={this.onChange}
                    required
                  ></input>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="description"
                    placeholder="Beskrivelse..."
                    className="form-control mb-2 mr-sm-2 col-md-6"
                    value={description}
                    onChange={this.onChange}
                  ></input>
                </div>
                <div className="form-group">
                  <div style={{ display: 'flex' }}>
                    <input
                      type="text"
                      name="color"
                      placeholder="Fargekode HEX"
                      className="form-control mb-2 mr-sm-2 col-md-6"
                      value={color}
                      onChange={this.onChange}
                    ></input>
                    {color && (
                      <div
                        style={{
                          width: 30,
                          height: 30,
                          background: color,
                          borderRadius: 5,
                        }}
                      >
                        &nbsp;
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="icon"
                    placeholder="fas fa-icon-name"
                    className="form-control mb-2 mr-sm-2 col-md-6"
                    value={icon}
                    onChange={this.onChange}
                  ></input>
                  <small id="emailHelp" className="form-text text-muted">
                    Bruk CSS-klasser fra Font Awesome &nbsp;
                    <a
                      href="https://fontawesome.com/icons d=gallery"
                      rel="noopener noreferrer"
                      title="Gå til fontawesome.com/"
                      target="_blank"
                    >
                      <i className="fas fa-external-link-alt"></i>
                    </a>
                  </small>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    name="requiresApproval"
                    id="requiresApproval"
                    onChange={this.onCheckboxChange}
                    checked={requiresApproval}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="requiresApproval"
                  >
                    Krever godkjenning
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    name="deductedLeave"
                    id="deductedLeave"
                    onChange={this.onCheckboxChange}
                    checked={deductedLeave}
                  />
                  <label className="form-check-label" htmlFor="deductedLeave">
                    Trekk feriedager
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    name="deductedEgenmelding"
                    id="deductedEgenmelding"
                    onChange={this.onCheckboxChange}
                    checked={deductedEgenmelding}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="deductedEgenmelding"
                  >
                    Trekk egenmelding
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    name="deductedOmsorgsdager"
                    id="deductedOmsorgsdager"
                    onChange={this.onCheckboxChange}
                    checked={deductedOmsorgsdager}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="deductedOmsorgsdager"
                  >
                    Trekk omsorgsdager
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    name="isSickness"
                    id="isSickness"
                    onChange={this.onCheckboxChange}
                    checked={isSickness}
                  />
                  <label className="form-check-label" htmlFor="isSickness">
                    Sykdom
                  </label>
                </div>

                <div className="form-group">
                  <button type="submit" className="btn btn-success  mb-2">
                    LEGG TIL <i className="fas fa-plus-circle"></i>
                  </button>
                </div>
              </form>
            </>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const AddLeaveTypeForm = compose(
  withAuthentication,
  withFirebase
)(AddLeaveTypeFormBase);

export default compose(
  withFirebase
)(AddLeaveTypeForm);
