import React, { Component } from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';

class DepartmentList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      departments: [],
      loading: false,
    };
  }
  componentDidMount() {
    this.setState({ loading: true });

    let collectionRef = this.props.firebase.departments();

    this.unsubscribe = collectionRef.onSnapshot((snapshot) => {
      let departments = [];

      snapshot.forEach((doc) =>
        departments.push({ ...doc.data(), uid: doc.id })
      );

      this.setState({
        departments,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { departments } = this.state;
    return (
      <>
        <table className="table">
          <thead>
            <tr>
              <th>Avdeling </th>
              <th className="text-center">Opprettet av</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {departments.map((department) => (
              <tr key={department.uid}>
                <td>
                  <strong>{department.name}</strong>
                </td>
                <td className="text-center align-middle">
                  {department.createdEmail}
                </td>
                <td className="text-right">
                  <Link
                    to={`/departments/edit/${department.uid}`}
                    className="btn btn-sm btn-primary mr-2"
                  >
                    <i className="far fa-edit"></i>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }
}

const condition = (authUser) => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase
)(DepartmentList);
