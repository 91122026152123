import React, { Component } from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';

//import moment from 'moment';

import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';

class LeaveTypeList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      leaveTypes: [],
      loading: false,
    };
  }

  onDelete = (uid, event) => {
    this.props.firebase
      .leaveTypeDelete(uid)
      .then(function () {
        console.log('Leave Type successfully deleted!');
      })
      .catch(function (error) {
        console.error('Error removing Leave Type : ', error);
      });
  };

  componentDidMount() {
    this.setState({ loading: true });

    let collectionRef = this.props.firebase.leaveTypes().orderBy('isSickness');

    this.unsubscribe = collectionRef.onSnapshot((snapshot) => {
      let leaveTypes = [];

      snapshot.forEach((doc) =>
        leaveTypes.push({ ...doc.data(), uid: doc.id })
      );

      // console.log(todos);
      this.setState({
        leaveTypes,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { leaveTypes } = this.state;
    return (
      <div>
        <table className="table">
          <thead>
            <tr>
              <th>
                Navn <br /> <small>Beskrivelse</small>
              </th>
              <th className="text-center">
                Krever
                <br />
                Godkjenning
              </th>
              <th className="text-center">
                Trekk
                <br /> Feriedager
              </th>
              <th className="text-center">
                Trekk
                <br /> Egenmelding
              </th>
              <th className="text-center">
                Trekk
                <br /> Omsorgsdager
              </th>
              <th className="text-center">Sykdom</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {leaveTypes.map((leaveType) => (
              <tr key={leaveType.uid}>
                <td>
                  <div style={{ display: 'flex' }}>
                    <strong>
                      {leaveType.icon && <i className={leaveType.icon}></i>}{' '}
                      {leaveType.name}
                    </strong>
                    {leaveType.color && (
                      <div
                        style={{
                          width: 15,
                          height: 15,
                          background: leaveType.color,
                          borderRadius: '50%',
                          marginLeft: 15,
                          alignSelf: 'center',
                        }}
                      >
                        &nbsp;
                      </div>
                    )}
                  </div>

                  <p>{leaveType.description}</p>
                </td>
                <td className="text-center align-middle">
                  {leaveType.requiresApproval && <div>JA</div>}
                </td>
                <td className="text-center align-middle">
                  {leaveType.deductedLeave && <div>JA</div>}
                </td>
                <td className="text-center align-middle">
                  {leaveType.deductedEgenmelding && <div>JA</div>}
                </td>
                <td className="text-center align-middle">
                  {leaveType.deductedOmsorgsdager && <div>JA</div>}
                </td>

                <td className="text-center align-middle">
                  {leaveType.isSickness && <div>JA</div>}
                </td>

                <td>
                  <Link
                    to={`/leavetypes/edit/${leaveType.uid}`}
                    className="btn btn-sm btn-primary mr-2"
                  >
                    <i className="far fa-edit"></i>
                  </Link>
                  {/*
                <button className="btn btn-sm btn-danger" onClick={(e) => this.onDelete(leaveType.uid, e)}><i className="far fa-trash-alt"></i></button>
                 */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

const condition = (authUser) => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase
)(LeaveTypeList);
