class FirestoreLeaveProfileApi {
  constructor(firestore, fieldValue, userApi) {
    this.firestore = firestore;
    // https://firebase.google.com/docs/reference/js/firebase.firestore.FieldValue#servertimestamp
    this.fieldValue = fieldValue;
    this.userApi = userApi;
  }

  getLeaveProfilesRefs() {
    return this.firestore.collection('leaveProfiles');
  }

  getLeaveProfileRef(leaveProfileId) {
    return this.firestore.doc(`leaveProfiles/${leaveProfileId}`);
  }

  getLeaveProfiles() {
    var leaveProfiles = [];
    this.getLeaveProfilesRefs()
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          leaveProfiles.push({ ...doc.data(), uid: doc.id });
        });
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
      });

    return leaveProfiles;
  }

  createLeaveProfile(data) {
    return this.firestore.collection('leaveProfiles').add({
      ...data,
      createdUserId: this.auth.currentUser.uid,
      createdEmail: this.auth.currentUser.email,
      created: this.fieldValue.serverTimestamp(),
      updated: null,
    });
  }

  updateLeaveProfile(leaveProfileId, data) {
    return this.firestore
      .collection('leaveProfiles')
      .doc(leaveProfileId)
      .set(
        {
          ...data,
          updated: this.fieldValue.serverTimestamp(),
        },
        { merge: true }
      );
  }

  deleteLeaveProfile(leaveProfileId) {
    return this.firestore
      .collection('leaveProfiles')
      .doc(leaveProfileId)
      .delete();
  }

  getDefaultLeaveProfile() {
    return this.firestore
      .collection('leaveProfiles')
      .where('isDefault', '==', true)
      .limit(1)
      .get()
      .then((querySnapshot) => {
        var defaultLeaveProfile = null;
        querySnapshot.forEach((doc) => {
          defaultLeaveProfile = { ...doc.data(), uid: doc.id };
        });
        return defaultLeaveProfile;
      });
  }

  setLeaveProfileAsDefault(leaveProfileId) {
    var self = this;
    return this.getLeaveProfilesRefs()
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          if (leaveProfileId === doc.id) {
            self.leaveProfileUpdate(doc.id, { isDefault: true });
          } else if (doc.get('isDefault')) {
            // set isDefault=false for existing default profiles
            self.leaveProfileUpdate(doc.id, { isDefault: false });
          }
        });
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
      });
  }

  getLeaveProfileOrDefaultForUser(userId) {
    return this.userApi.getUser(userId).then((user) => {
      if (!user || !user.leaveProfileUid) {
        return this.getDefaultLeaveProfile();
      }

      const userLeaveProfile = this.getLeaveProfileRef(user.leaveProfileUid)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            return { ...doc.data(), uid: doc.id };
          } else {
            console.log('LeaveProfile not found: ', userId);
            return null;
          }
        });

      if (!userLeaveProfile) {
        return this.getDefaultLeaveProfile();
      }

      return userLeaveProfile;
    });
  }
}

export default FirestoreLeaveProfileApi;
