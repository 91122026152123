import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useLocation } from 'react-router-dom';
import querystring from 'query-string';
import { withFirebase } from '../Firebase';
import LeaveRequestAction from './LeaveRequestAction';

const LeaveRequestActionContainer = (props) => {
  const location = useLocation();
  const { firebase } = props;
  const { leaveRequestId, leaveRequestAnswer } = querystring.parse(
    location.search
  );

  return (
    <LeaveRequestAction
      leaveRequestId={leaveRequestId}
      leaveRequestAnswer={leaveRequestAnswer}
      onLeaveApproved={(leaveRequestId) => {
        return firebase.leaveRequestApprove(leaveRequestId);
      }}
      onLeaveRejected={(leaveRequestId) => {
        return firebase.leaveRequestReject(leaveRequestId);
      }}
    />
  );
};

LeaveRequestActionContainer.propTypes = {
  firebase: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default compose(withFirebase)(LeaveRequestActionContainer);
