import React from "react";

const DebouncedInput = ({ value: initialValue, onChange, debounce = 500, ...props}) => {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
    // disabling as the given values will not change on rerender.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}

export default DebouncedInput;
