import React, { Component } from 'react';
import { compose } from 'recompose';

import { withAuthorization, isAdmin } from '../Session';
import { withFirebase } from '../Firebase';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const PATH_BASE = 'https://webapi.no/api/v1/holidays';
const PATH_SEARCH = '/' + new Date().getFullYear();

const INITIAL_STATE = {
  loading: false,
  description: '',
  date: new Date(),
  searchTerm: PATH_SEARCH,
};

class AddHoliday extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };

    this.getHolidays = this.getHolidays.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  getHolidays(result) {
    const holidays = result.data;
    holidays.forEach((holiday) => {
      const docId = new Date(holiday.date).toDateString();
      this.props.firebase.firestore
        .collection('bankHolidays')
        .doc(docId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            console.log('Document data:', doc.data());
          } else {
            this.props.firebase.holidayAddApi(holiday);
          }
        });
    });
  }

  handleClick = (event) => {
    const { searchTerm } = this.state;
    fetch(`${PATH_BASE}${searchTerm}`)
      .then((response) => response.json())
      .then((result) => this.getHolidays(result))
      .catch((error) => error);

    event.preventDefault();
  };

  onSubmit = (event) => {
    const { description, date } = this.state;

    this.props.firebase
      .holidayAdd({
        description,
        date,
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        console.log('holiday added');
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onHandleDateChange = (date) => {
    this.setState({
      date: date,
    });
  };

  render() {
    const { description } = this.state;
    return (
      <>
        <br />
        <br />
        <div className="offset-sm-2">
          <h4>Hent fra webapi</h4>
        </div>
        <div className="offset-sm-2">
          <button className="btn btn-success" onClick={this.handleClick}>
            HENT <i className="fas fa-arrow-circle-down"></i>
          </button>
        </div>

        <br />
        <br />

        <div className="offset-sm-2">
          <h4>Ny helligdag</h4>
        </div>
        <form onSubmit={this.onSubmit}>
          <div className="form-group row">
            <label
              htmlFor="inputName"
              className="col-sm-2 col-form-label text-right"
            >
              Beskrivelse:
            </label>
            <div className="col-sm-6">
              <input
                type="text"
                name="description"
                id="inputName"
                placeholder="F.eks. første påskedag"
                className="form-control"
                value={description}
                onChange={this.onChange}
                required
              ></input>
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="inputName"
              className="col-sm-2 col-form-label text-right"
            >
              Dato:
            </label>
            <div className="col-sm-6">
              <DatePicker
                name="date"
                selected={this.state.date}
                onChange={this.onHandleDateChange}
              />
            </div>
          </div>

          <div className="offset-sm-2">
            <button type="submit" className="btn btn-success  mb-2">
              LEGG TIL <i className="fas fa-plus-circle"></i>
            </button>
          </div>
        </form>
      </>
    );
  }
}

export default compose(withAuthorization(isAdmin), withFirebase)(AddHoliday);
