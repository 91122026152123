import React from 'react';
import { useParams } from "react-router-dom";

const withParams = (Component) => {
  const WithParams = (props) => {
    const params = useParams();
    return (
      <Component {...props} params={params} />
    )
  }
  return WithParams;
}

export default withParams;
