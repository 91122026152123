import React from 'react';
import { compose } from 'recompose';
import sumBy from 'lodash/sumBy';
import groupBy from 'lodash/groupBy';
import { withFirebase } from '../Firebase';
import { withAuthorization, isAdmin } from '../Session';

class ReportsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      leaveRequestsByYear: {},
      leaveTypes: [],
      report: {
        total: {},
        byLeaveType: {},
        byYear: {},
      },
      loading: false,
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });

    for (let i = 2018; i < new Date().getFullYear(); i++) {
      const leaveRequests = await this.props.firebase.approvedLeaveRequestsForAllUsers(
        i
      );

      this.setState({
        leaveRequestsByYear: {
          ...this.state.leaveRequestsByYear,
          [i]: leaveRequests.map((l) => {
            return {
              ...l,
              created: l.created.toDate(),
              createdYear: l.created.toDate().getFullYear(),
            };
          }),
        },
      });
    }

    const leaveTypes = await this.props.firebase.leaveTypeCollection();
    this.setState({
      leaveTypes: Object.keys(leaveTypes).map((key) => leaveTypes[key]),
    });

    const report = Object.keys(this.state.leaveRequestsByYear).reduce(
      (report, year) => {
        if (!report.byYear[year]) {
          report.byYear[year] = {
            totalDays: 0,
            totalHours: 0,
            totalWorkDays: 0,
          };
        }

        const leaveRequests = this.state.leaveRequestsByYear[year];

        if (leaveRequests.length > 0) {
          report.byYear[year].totalDays += sumBy(leaveRequests, 'totalDays');
          report.byYear[year].totalHours += sumBy(leaveRequests, 'totalHours');
          report.byYear[year].totalWorkDays += sumBy(leaveRequests, 'workDays');

          report.total.totalDays += sumBy(leaveRequests, 'totalDays');
          report.total.totalHours += sumBy(leaveRequests, 'totalHours');
          report.total.totalWorkDays += sumBy(leaveRequests, 'workDays');
        }

        const leaveTypeGroups = groupBy(leaveRequests, 'leaveTypeUid');

        Object.keys(leaveTypeGroups).forEach((leaveTypeUid) => {
          if (!report.byLeaveType[leaveTypeUid]) {
            report.byLeaveType[leaveTypeUid] = {
              totalDays: 0,
              totalHours: 0,
              totalWorkDays: 0,
              byYear: {},
            };
          }

          report.byLeaveType[leaveTypeUid].totalDays += sumBy(
            leaveTypeGroups[leaveTypeUid],
            'totalDays'
          );
          report.byLeaveType[leaveTypeUid].totalHours += sumBy(
            leaveTypeGroups[leaveTypeUid],
            'totalHours'
          );
          report.byLeaveType[leaveTypeUid].totalWorkDays += sumBy(
            leaveTypeGroups[leaveTypeUid],
            'workDays'
          );

          // Vi vil også se fraværstype for hvert år, f.eks "Ferie 2021" eller "Permisjon 2022"
          const leaveTypeByYearGroups = groupBy(
            leaveTypeGroups[leaveTypeUid],
            'createdYear'
          );

          Object.keys(leaveTypeByYearGroups).forEach((year) => {
            if (!report.byLeaveType[leaveTypeUid].byYear[year]) {
              report.byLeaveType[leaveTypeUid].byYear[year] = {
                totalDays: 0,
                totalHours: 0,
                totalWorkDays: 0,
              };
            }

            const group = leaveTypeByYearGroups[year];

            report.byLeaveType[leaveTypeUid].byYear[year].totalDays += sumBy(
              group,
              'totalDays'
            );
            report.byLeaveType[leaveTypeUid].byYear[year].totalHours += sumBy(
              group,
              'totalHours'
            );
            report.byLeaveType[leaveTypeUid].byYear[
              year
            ].totalWorkDays += sumBy(group, 'workDays');
          });
        });

        return report;
      },
      {
        total: {
          totalDays: 0,
          totalHours: 0,
          totalWorkDays: 0,
        },
        byLeaveType: {},
        byYear: {},
      }
    );

    console.log('report', report);

    this.setState({ report: report, loading: false });
  }

  render() {
    if (
      this.state.loading ||
      Object.keys(this.state.report.byLeaveType).length === 0 ||
      Object.keys(this.state.report.byYear).length === 0
    ) {
      return <p>Knasker data... nom nom nom</p>;
    }
    return (
      <div>
        <h3>Rapporter</h3>
        <hr />

        <h3>Totalt</h3>
        {this.state.report.total && (
          <ul>
            <li>Antall dager: {this.state.report.total.totalDays}</li>
            <li>
              Antall arbeidsdager: {this.state.report.total.totalWorkDays}
            </li>
            <li>Antall timer: {this.state.report.total.totalHours}</li>
          </ul>
        )}
        {!this.state.report.total && <p>Mangler data</p>}

        <h3>Etter fraværstype</h3>
        {Object.keys(this.state.report.byLeaveType).length > 0 && (
          <div>
            {Object.keys(this.state.report.byLeaveType).map((key) => (
              <div key={key} style={{ marginBottom: 10 }}>
                <p>
                  {this.state.leaveTypes.find((lt) => lt.uid === key).name ||
                    key}
                </p>
                <ul>
                  <li>
                    Antall dager:&nbsp;
                    <strong>
                      {this.state.report.byLeaveType[key].totalDays}
                    </strong>
                  </li>
                  <li>
                    Antall arbeidsdager:
                    <strong>
                      {this.state.report.byLeaveType[key].totalWorkDays}
                    </strong>
                  </li>
                  <li>
                    Antall timer:&nbsp;
                    <strong>
                      {this.state.report.byLeaveType[key].totalHours}
                    </strong>
                  </li>
                </ul>

                {Object.keys(this.state.report.byLeaveType[key].byYear).map(
                  (year) => (
                    <div
                      key={`leavetype_for_${year}`}
                      style={{ marginLeft: 20 }}
                    >
                      <p>{year}</p>
                      <div>
                        <ul>
                          <li>
                            Antall dager i {year}:&nbsp;
                            <strong>
                              {
                                this.state.report.byLeaveType[key].byYear[year]
                                  .totalDays
                              }
                            </strong>
                          </li>
                          <li>
                            Antall arbeidsdager i {year}:
                            <strong>
                              {
                                this.state.report.byLeaveType[key].byYear[year]
                                  .totalWorkDays
                              }
                            </strong>
                          </li>
                          <li>
                            Antall timer i {year}:&nbsp;
                            <strong>
                              {
                                this.state.report.byLeaveType[key].byYear[year]
                                  .totalHours
                              }
                            </strong>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )
                )}
              </div>
            ))}
          </div>
        )}
        {!this.state.report.byLeaveType && <p>Mangler data</p>}

        <h3>Etter år</h3>
        {Object.keys(this.state.report.byYear).length > 0 && (
          <div>
            {Object.keys(this.state.report.byYear).map((key) => (
              <div key={`year_${key}`} style={{ marginBottom: 10 }}>
                <p>{key}</p>
                <ul>
                  <li>
                    Antall dager:{' '}
                    <strong>{this.state.report.byYear[key].totalDays}</strong>
                  </li>
                  <li>
                    Antall arbeidsdager:
                    <strong>
                      {this.state.report.byYear[key].totalWorkDays}
                    </strong>
                  </li>
                  <li>
                    Antall timer:{' '}
                    <strong>{this.state.report.byYear[key].totalHours}</strong>
                  </li>
                </ul>
              </div>
            ))}
          </div>
        )}
        {!this.state.report.byYear && <p>Mangler data</p>}
      </div>
    );
  }
}

export default compose(withAuthorization(isAdmin), withFirebase)(ReportsPage);
