import React, { Component } from 'react';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import { withAuthorization, isAdmin } from '../Session';
import { Link } from 'react-router-dom';

class AdminPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      users: [],
      leaveProfiles: [],
      leaveProfileUid: null,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    const leaveProfiles = this.props.firebase.leaveProfilesCollection();

    this.unsubscribe = this.props.firebase
      .usersFirestore()
      .onSnapshot((snapshot) => {
        let users = [];
        snapshot.forEach((doc) => users.push({ ...doc.data(), uid: doc.id }));

        this.setState({
          users,
          leaveProfiles,
          loading: false,
        });
      });
  }

  onAddAdmin(uid) {
    this.props.firebase.userAdmin(uid);
  }

  onRemoveAdmin(uid) {
    this.props.firebase.userRemoveAdmin(uid);
  }

  onAddAdminEmail(uid) {
    this.props.firebase.userAdminEmail(uid);
  }

  onRemoveAdminEmail(uid) {
    this.props.firebase.userRemoveAdminEmail(uid);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  onleaveProfileChangege = (user, event) => {
    this.props.firebase.userUpdate(user.uid, {
      leaveProfileUid: event.target.value,
    });
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { users, leaveProfiles, loading } = this.state;
    return (
      <div>
        <h3>Brukere</h3>
        <p>Håndtering og redigering av brukere</p>
        {loading && <div>Loading ...</div>}
        {!loading && (
          <table className="table">
            <thead>
              <tr>
                <th style={{ minWidth: 180 + 'px' }}>Navn</th>
                <th style={{ minWidth: 50 + 'px', overflow: 'hidden' }}>
                  Epost
                </th>
                <th>Profil</th>
                <th>Funksjoner</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.uid}>
                  <td
                    style={{
                      maxWidth: 180 + 'px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      textDecoration: user.deactivated ? "line-through" : "none",
                    }}
                  >
                    <img
                      src={user.photoURL}
                      className="profile-photo"
                      alt="Profile"
                    />
                    {user.displayName}
                  </td>
                  <td
                    style={{
                      maxWidth: 0 + 'px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <small>{user.email}</small>
                  </td>
                  <td>
                    <select
                      name="leaveProfileUid"
                      onChange={(e) => this.onleaveProfileChangege(user, e)}
                      className="form-control"
                      defaultValue={user.leaveProfileUid}
                    >
                      {!user.leaveProfileUid && (
                        <option value={null}>--</option>
                      )}
                      {leaveProfiles &&
                        leaveProfiles.map((item) => (
                          <option value={item.uid} key={item.uid}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </td>
                  <td style={{ minWidth: 10 + 'rem' }}>
                    <Link
                      to={`/users/edit/${user.uid}`}
                      className="btn btn-primary btn-sm"
                    >
                      <i
                        className="fas fa-user-edit"
                        style={{ width: 20 + 'px' }}
                      />
                    </Link>
                    &nbsp;
                    {user.roles && !user.roles['ADMIN'] && (
                      <button
                        className="btn btn-success  btn-sm"
                        onClick={() => this.onAddAdmin(user.uid)}
                      >
                        <i
                          className="fas fa-plus-circle"
                          style={{ width: 20 + 'px' }}
                        />
                      </button>
                    )}
                    {user.roles && user.roles['ADMIN'] && (
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => this.onRemoveAdmin(user.uid)}
                      >
                        <i
                          className="fas fa-minus-circle"
                          style={{ width: 20 + 'px' }}
                        />
                      </button>
                    )}
                    &nbsp;
                    {user.roles &&
                      user.roles['ADMIN'] &&
                      !user.roles['ADMIN_EMAIL'] && (
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => this.onAddAdminEmail(user.uid)}
                          title={'Mottar ikke - trykk for å legge inn'}
                        >
                          <i
                            className="fas fa-bell"
                            style={{ width: 20 + 'px' }}
                          />
                        </button>
                      )}
                    {user.roles &&
                      user.roles['ADMIN'] &&
                      user.roles['ADMIN_EMAIL'] && (
                        <button
                          className="btn btn-success btn-sm"
                          onClick={() => this.onRemoveAdminEmail(user.uid)}
                          title={'Brukeren skal motta - trykk for å fjerne'}
                        >
                          <i
                            className="fas fa-bell-slash"
                            style={{ width: 20 + 'px' }}
                          />
                        </button>
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

export default compose(withAuthorization(isAdmin), withFirebase)(AdminPage);
