import React from 'react';
import { compose } from 'recompose';
import { withAuthorization, isAdmin } from '../Session';
import { withFirebase } from '../Firebase';
import EditDepartmentPage from './EditDepartmentPage';
import DepartmentList from './DepartmentList';

const DepartmentPage = () => (
  <div>
    <h3>Avdeling</h3>
    <hr className="mb-5" />
    <EditDepartmentPage />
    <DepartmentList />
  </div>
);

export default compose(
  withAuthorization(isAdmin),
  withFirebase
)(DepartmentPage);
