import React from 'react';
import CalendarTimeline, { MODE_EVERYONE } from '../CalendarTimeline';
import {
  AuthUserContext,
  withAuthorization,
  isAdmin,
  isSignedInUser,
} from '../Session';
import Legend from '../CalendarTimeline/Legend'
import LeaveRequestAdminTableView from "../LeaveRequest/LeaveRequestAdminTable";

const HomePage = () => (
  <div>
    <AuthUserContext.Consumer>
      {(authUser) => (
        <>
          <div className="row">
            <div className="col-md-12 pt-5">
              {isAdmin(authUser) && (
                <div style={{ marginBottom: 40 }}>
                  <h3>Ubesvarte fraværssøknader</h3>
                  <p>
                    <i>Du ser dette fordi du er administrator</i>
                  </p>
                  <LeaveRequestAdminTableView onlyPending/>
                </div>
              )}
              <div>
                <h3>Fraværsoversikt</h3>
                <p>
                  <i>Fraværsinformasjon for hele Apparat</i>
                </p>
                <CalendarTimeline mode={MODE_EVERYONE} enableSummary={true} authUser={authUser}/>
                <Legend/>
              </div>
            </div>
          </div>
        </>
      )}
    </AuthUserContext.Consumer>
  </div>
);

export default withAuthorization(isSignedInUser)(HomePage);
