import React from 'react';
import PropTypes from 'prop-types';
import CalendarTimeline from './CalendarTimeline';
import moment from 'moment';
import { withFirebase } from '../Firebase';
import { MODE_SINGLE_USER, MODE_EVERYONE } from './constants';

const APPROVED_COLOR = '#8fd19e';

class CalendarTimelineContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [{ id: 1, title: 'No data' }],
      items: [],
      leaveRequests: [],
      mode: props.mode,
    };
    this.loadCalendarForEveryone = this.loadCalendarForEveryone.bind(this);
    this.loadCalendarForSingleUser = this.loadCalendarForSingleUser.bind(this);
    this.buildItems = this.buildItems.bind(this);
    this.buildGroups = this.buildGroups.bind(this);
  }

  buildGroups(leaveRequests, titleSelectorFn) {
    return leaveRequests.reduce((all, cur, i) => {
      const hasLeaveType = all.find((a) => a.title === titleSelectorFn(cur));
      if (hasLeaveType) {
        return all;
      } else {
        return [...all, { id: i + 1, title: titleSelectorFn(cur)}];
      }
    }, []);
  }

  buildItems(leaveRequests, groups, groupTitleSelectorFn) {
    return leaveRequests.reduce((all, cur, i) => {
      if(!cur.leaveType) { // do not draw leave when it does not exist.
        return [...all];
      }
      const dateStartWholeDay = cur.dateStartMoment.set({
        hour: 8,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      const dateEndWholeDay = cur.dateEndMoment.set({
        hour: 16,
        minute: 0,
        second: 0,
        millisecond: 0,
      });


      const title = cur.leaveType.name + ': ' + cur.dateStartMoment.format('l') + ' - ' + cur.dateEndMoment.format('l');

      const item = {
        id: i + 1,
        group: groups.find((g) => g.title === groupTitleSelectorFn(cur)).id,
        title: title,
        start_time: dateStartWholeDay,
        end_time: dateEndWholeDay,
        bgColor: cur.leaveType.color || APPROVED_COLOR,
        leaveRequestId: cur.uid,
      };
      return [...all, item];
    }, []);
  }

  loadCalendarForSingleUser(userId) {
    const currentYear = moment().year();

    this.props.firebase
      .leaveRequestsWithType(userId, currentYear)
      .then((leaveRequests) => {
        const groups = this.buildGroups(
          leaveRequests,
          (lr) => lr.leaveType.name
        );

        const items = this.buildItems(
          leaveRequests,
          groups,
          (lr) => lr.leaveType.name
        );

        this.setState({
          groups,
          items,
          leaveRequests,
        });
      });
  }

  loadCalendarForEveryone() {
    const currentYear = moment().year();

    this.props.firebase
      .allUsersIncludingApprovedLeaveRequests(currentYear)
      .then((leaveRequests) => {
        const groups = this.buildGroups(
          leaveRequests,
          (lr) => lr.user.displayName
        );

        groups.sort((a, b) => { // sorter på navn
          const nameA = a.title.toUpperCase();
          const nameB = b.title.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          return 0;
        });

        const items = this.buildItems(
          leaveRequests,
          groups,
          (lr) => lr.user.displayName
        );

        this.setState({
          groups,
          items,
          leaveRequests,
        });
      });
  }

  componentDidMount() {
    switch (this.props.mode) {
      case MODE_SINGLE_USER:
        return this.loadCalendarForSingleUser(this.props.user.uid);
      case MODE_EVERYONE:
        return this.loadCalendarForEveryone();
      default:
        throw new Error(`unknown mode ${this.props.mode}`);
    }
  }

  render() {
    const { groups, items, leaveRequests } = this.state;
    const { enableSummary } = this.props;
    return (
      <CalendarTimeline
        groups={groups}
        items={items}
        leaveRequests={leaveRequests}
        enableSummary={enableSummary}
        authUser={this.props.authUser}
      />
    );
  }
}

CalendarTimelineContainer.propTypes = {
  user: PropTypes.object,
  mode: PropTypes.string.isRequired,
  enableSummary: PropTypes.bool,
};

CalendarTimelineContainer.defaultProps = {
  user: null,
  mode: MODE_EVERYONE,
  enableSummary: false,
};

export default withFirebase(CalendarTimelineContainer);
