import React, { Component } from 'react';
import { compose } from 'recompose';
import moment from 'moment';
import { withAuthorization, isAdmin } from '../Session';
import { withFirebase } from '../Firebase';

class BankHolidayList extends Component {
  constructor(props) {
    super(props);

    this.state = { bankHolidays: [] };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });
    let collectionRef = this.props.firebase.bankHolidays();

    this.unsubscribe = collectionRef.orderBy("date").onSnapshot((snapshot) => {
      let bankHolidays = [];
      snapshot.forEach((doc) =>
        bankHolidays.push({ ...doc.data(), uid: doc.id })
      );
      this.setState({
        bankHolidays,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  onActivate(uid) {
    this.props.firebase.holidayActivate(uid).then(function () {
      console.log('activated');
    });
  }

  onDeactivate(uid) {
    this.props.firebase.holidayDeactivate(uid).then(function () {
      console.log('deactivated');
    });
  }

  onDelete = (uid, event) => {
    this.props.firebase
      .bankHolidayDelete(uid)
      .then(function () {
        console.log('holiday deleted');
      })
      .catch(function (error) {
        console.error('Error removing Holiday : ', error);
      });
  };

  render() {
    const { bankHolidays } = this.state;
    return (
      <>
        <table className="table">
          <thead>
            <tr>
              <th>Beskrivelse</th>
              <th>Dato</th>
              <th>Er aktiv</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {bankHolidays.map((bankHoliday) => (
              <tr
                key={bankHoliday.uid}
                className={(bankHoliday.isActive && 'table-success').toString()}
              >
                <td>
                  <strong>{bankHoliday.description}</strong>
                </td>
                <td>
                  {moment(bankHoliday.date.toDate()).format('dddd Do MMMM, YYYY')}
                </td>
                <td>
                  {bankHoliday.isActive && (
                    <button
                      className="btn btn-sm btn-danger w-50"
                      onClick={() => {
                        this.onDeactivate(bankHoliday.uid);
                      }}
                    >
                      Deaktiver
                    </button>
                  )}

                  {!bankHoliday.isActive && (
                    <button
                      className="btn btn-sm btn-success w-50"
                      onClick={() => {
                        this.onActivate(bankHoliday.uid);
                      }}
                    >
                      Aktiver
                    </button>
                  )}
                </td>
                <td>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={(e) => this.onDelete(bankHoliday.uid, e)}
                  >
                    <i className="far fa-trash-alt"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }
}

export default compose(
  withAuthorization(isAdmin),
  withFirebase
)(BankHolidayList);
