class UserStats {
  constructor(year) {
    this.stats = {
      ferieDagerTilgjengelig: 0, // dager per år
      ferieTimerBrukt: 0,

      egenmeldingsDagerTilgjengelig: 0, // dager per år
      egenmeldingsTimerBrukt: 0,

      omsorgsDagerTilgjengelig: 0, // dager per år
      omsorgsTimerBrukt: 0,

      hoursPerDay: 7.5, // default value until leaveProfile is loaded
      year: year || new Date().getFullYear(), // default to current year
      extraDays: 0, // ekstra feriedager for gjeldende år
    };

    this.leaveProfile = null;
    this.user = null;
    this.leaveRequests = null;
  }

  static load(data) {
    const userStats = new UserStats();

    if (data.year) {
      userStats.setYear(data.year);
    }

    if (data.user) {
      userStats.setUser(data.user);
    }

    if (data.leaveProfile) {
      userStats.setLeaveProfile(data.leaveProfile);
    }

    if (data.leaveRequests) {
      userStats.setLeaveRequestsWithType(data.leaveRequests);
    }

    return userStats;
  }

  getStats() {
    return {
      ...this.stats,
      totalFerieDager: this.getTotalFerieDager(),
      totalFerieTimer: this.getTotalFerieTimer(),

      //-- Dager brukt
      ferieDagerBrukt: this.getFerieDagerBrukt(),
      egenmeldingsDagerBrukt: this.getEgenmeldingsDagerBrukt(),
      omsorgsDagerBrukt: this.getOmsorgsDagerBrukt(),

      // dager remaining
      ferieTimerRemaining:
        this.getTotalFerieTimer() - this.stats.ferieTimerBrukt,
      ferieDagerRemaining:
        (this.getTotalFerieTimer() - this.stats.ferieTimerBrukt) /
        this.stats.hoursPerDay,

      //-- prosent Brukt
      ferieTimerBruktProsent: this.getFerieTimerBruktProsent(),
      egenmeldingsTimerBruktProsent: this.getEgenmeldingsTimerBruktProsent(),
      omsorgsTimerBruktProsent: this.getOmsorgsTimerBruktProsent(),
    };
  }

  setYear(year) {
    this.stats.year = year;
    return this;
  }

  setUser(user) {
    this.user = user;
    if (user.extraDays && user.extraDays[this.stats.year]) {
      this.stats.extraDays = user.extraDays[this.stats.year];
    }

    return this;
  }

  setLeaveProfile(leaveProfile) {
    this.leaveProfile = leaveProfile;
    this.stats.hoursPerDay = this.leaveProfile.hoursPerDay;

    this.stats.ferieDagerTilgjengelig = leaveProfile.ferieDager;
    this.stats.egenmeldingsDagerTilgjengelig = leaveProfile.egenmeldingsDager;
    this.stats.omsorgsDagerTilgjengelig = leaveProfile.omsorgsDager;

    return this;
  }

  setLeaveRequestsWithType(leaveRequests) {
    this.leaveRequests = leaveRequests;

    leaveRequests.forEach((leaveRequest) => {
      if (leaveRequest.leaveType.deductedLeave) {
        this.stats.ferieTimerBrukt += leaveRequest.totalHours;
      }
      if (leaveRequest.leaveType.deductedEgenmelding) {
        this.stats.egenmeldingsTimerBrukt += leaveRequest.totalHours;
      }
      if (leaveRequest.leaveType.deductedOmsorgsdager) {
        this.stats.omsorgsTimerBrukt += leaveRequest.totalHours;
      }
    });

    return this;
  }

  // årlige feriedager + ekstra dager
  getTotalFerieDager() {
    return this.stats.ferieDagerTilgjengelig + this.stats.extraDays;
  }

  // timer fra årlige feriedager + ekstra dager
  getTotalFerieTimer() {
    return this.getTotalFerieDager() * this.stats.hoursPerDay;
  }

  //-- Dager brukt
  getFerieDagerBrukt() {
    if (this.stats.ferieTimerBrukt === 0) return 0;
    return this.stats.ferieTimerBrukt / this.stats.hoursPerDay;
  }
  getEgenmeldingsDagerBrukt() {
    if (this.stats.egenmeldingsTimerBrukt === 0) return 0;
    return this.stats.egenmeldingsTimerBrukt / this.stats.hoursPerDay;
  }
  getOmsorgsDagerBrukt() {
    if (this.stats.omsorgsTimerBrukt === 0) return 0;
    return this.stats.omsorgsTimerBrukt / this.stats.hoursPerDay;
  }

  //-- Timer Tilgjengelig
  getFerieTimerTilgjengelig() {
    return this.getTotalFerieDager() * this.stats.hoursPerDay;
  }
  getEgenmeldingsTimerTilgjengelig() {
    return this.stats.egenmeldingsDagerTilgjengelig * this.stats.hoursPerDay;
  }
  getOmsorgsTimerTilgjengelig() {
    return this.stats.omsorgsDagerTilgjengelig * this.stats.hoursPerDay;
  }

  //-- prosentBrukt
  getFerieTimerBruktProsent() {
    return (
      (this.stats.ferieTimerBrukt / this.getFerieTimerTilgjengelig()) * 100
    );
  }
  getEgenmeldingsTimerBruktProsent() {
    return (
      (this.stats.egenmeldingsTimerBrukt /
        this.getEgenmeldingsTimerTilgjengelig()) *
      100
    );
  }
  getOmsorgsTimerBruktProsent() {
    return (
      (this.stats.omsorgsTimerBrukt / this.getOmsorgsTimerTilgjengelig()) * 100
    );
  }
  //-- ubrukte timer dersom valgt requestLeave blir godkjent
  getRemainingFerieTimerIfApproved(timer) {
    return (
      this.getFerieTimerTilgjengelig() - (this.stats.ferieTimerBrukt + timer)
    );
  }
  getRemainingEgenmeldingsTimerIfApproved(timer) {
    return (
      this.getEgenmeldingsTimerTilgjengelig() -
      (this.stats.egenmeldingsTimerBrukt + timer)
    );
  }
  getRemainingOmsorgsTimerIfApproved(timer) {
    return (
      this.getOmsorgsTimerTilgjengelig() -
      (this.stats.omsorgsTimerBrukt + timer)
    );
  }

  //-- ubrukte dager dersom valgt requestLeave blir godkjent
  getRemainingFerieDagerIfApproved(timer) {
    return (
      this.getRemainingFerieTimerIfApproved(timer) / this.stats.hoursPerDay
    );
  }
  getRemainingEgenmeldingsDagerIfApproved(timer) {
    return (
      this.getRemainingEgenmeldingsTimerIfApproved(timer) /
      this.stats.hoursPerDay
    );
  }
  getRemainingOmsorgsDagerIfApproved(timer) {
    return (
      this.getRemainingOmsorgsTimerIfApproved(timer) / this.stats.hoursPerDay
    );
  }
}

export default UserStats;
