import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const Spinner = ({ enabled, color }) => (
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    <Loader
      type="Puff"
      color={color}
      height={100}
      width={100}
      visible={enabled}
    />
  </div>
);

Spinner.propTypes = {
  enabled: PropTypes.bool,
  color: PropTypes.string,
};

Spinner.defaultProps = {
  enabled: false,
  color: '#00BFFF',
};

export default Spinner;
