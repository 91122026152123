import moment from 'moment';

class FirestoreLeaveRequestApi {
  constructor(firestore, fieldValue, leaveTypeApi, userApi) {
    this.firestore = firestore;
    // https://firebase.google.com/docs/reference/js/firebase.firestore.FieldValue#servertimestamp
    this.fieldValue = fieldValue;
    this.leaveTypeApi = leaveTypeApi;
    this.userApi = userApi;
  }

  getLeaveRequestsRefs() {
    return this.firestore.collection('leaveRequests');
  }

  getLeaveRequestRef(leaveRequestId) {
    return this.firestore.doc(`leaveRequests/${leaveRequestId}`);
  }

  /**
   *  Return array of approved leaveRequest for specified user and year, joined with leaveTypes
   */
  getLeaveRequestsInYearForUser(userId, year) {
    return this.leaveTypeApi.getLeaveTypes().then((leaveTypes) => {
      return this.getLeaveRequestsRefs()
        .where('approvedStatus', '==', 'approved')
        .where('status', '==', 'active')
        .where('userUid', '==', userId)
        .where('dateStart', '>=', new Date(year, 0, 1))
        .where('dateStart', '<', new Date(year, 11, 31))
        .get()
        .then((querySnapshot) => {
          let leaveRequests = [];
          querySnapshot.forEach(function (doc) {
            const data = doc.data();
            const dateStartMoment = moment(data.dateStart.toDate());
            const dateEndMoment = moment(data.dateEnd.toDate());
            leaveRequests.push({
              ...data,
              uid: doc.id,
              leaveType: leaveTypes[doc.get('leaveTypeUid')],
              dateStartMoment,
              dateEndMoment,
            });
          });
          return leaveRequests;
        });
    });
  }

  getAllUsersIncludingApprovedLeaveRequestsInYear( year) {
    return this.leaveTypeApi.getLeaveTypes().then(async (leaveTypes) => {
      const leaveRequests = await this.getLeaveRequestsRefs()
        .where('approvedStatus', '==', 'approved')
        .where('status', '==', 'active')
        .where('dateStart', '>=', new Date(year, 0, 1))
        .where('dateStart', '<', new Date(year, 11, 31))
        .get()
        .then(querySnapshot => {
          let docs = []
          querySnapshot.forEach(async doc => docs.push( { ...doc.data(), docId: doc.id }));
          return docs;
        });

      const users = await this.userApi.getUsers();
      const activeUsers = users.filter(u => u.deactivated == null);

      return activeUsers.map(au => {
        const data = leaveRequests.filter(lr => lr.userUid === au.uid);
        if(data.length < 1){
          return { user: au }
        }

        return data.map(d => {
          const dateStartMoment = d ? moment(d.dateStart.toDate()) : moment(new Date());
          const dateEndMoment = d ? moment(d.dateEnd.toDate()) : moment(new Date());

          //uses leaveType to determine wether to draw leave in table
          const leaveType = d ? leaveTypes[d.leaveTypeUid] : undefined;
          return {
            ...d,
            uid: d?.docId || undefined,
            leaveType,
            dateStartMoment,
            dateEndMoment,
            user: au
          }
        })
      }).flat();
    });
  }

  getLeaveRequestsInYearForAllUsers(year) {
    return this.leaveTypeApi.getLeaveTypes().then((leaveTypes) => {
      return this.userApi.getUsers().then((users) => {
        return this.getLeaveRequestsRefs()
          .where('approvedStatus', '==', 'approved')
          .where('status', '==', 'active')
          .where('dateStart', '>=', new Date(year, 0, 1))
          .where('dateStart', '<', new Date(year, 11, 31))
          .get()
          .then((querySnapshot) => {
            let leaveRequests = [];
            querySnapshot.forEach(async function (doc) {
              const data = doc.data();
              const user = users.find((u) => u.uid === data.userUid);
              const dateStartMoment = moment(data.dateStart.toDate());
              const dateEndMoment = moment(data.dateEnd.toDate());

              if (user.deactivated == null) { // fjerner deaktiverte brukere fra oversikten
                leaveRequests.push({
                  ...data,
                  uid: doc.id,
                  leaveType: leaveTypes[doc.get('leaveTypeUid')],
                  dateStartMoment,
                  dateEndMoment,
                  user,
                });
              }
            });
            return leaveRequests;
          });
      });
    });
  }

  createLeaveRequest(data, requestDays, auth) {
    return this.firestore
      .collection('leaveRequests')
      .add({
        ...data,
        userUid: auth.currentUser.uid,
        userEmail: auth.currentUser.email,
        userName: auth.currentUser.displayName,
        created: this.fieldValue.serverTimestamp(),
        updated: null,
      })
      .then((docRef) => {
        if (requestDays && requestDays.length !== 0) {
          Object.keys(requestDays).forEach((key) => {
            // -- add
            this.firestore
              .collection('leaveRequests')
              .doc(docRef.id)
              .collection('requestDays')
              .add(requestDays[key]);
          });
        }
      });
  }

  deleteLeaveRequest(leaveRequestId) {
    this.deleteLeaveRequestDays(leaveRequestId);

    return this.firestore
      .collection('leaveRequests')
      .doc(leaveRequestId)
      .delete();
  }

  deleteLeaveRequestDays(leaveRequestId) {
    this.firestore
      .collection('leaveRequests')
      .doc(leaveRequestId)
      .collection('requestDays')
      .get()
      .then((collection) => {
        collection.forEach((document) => {
          document.ref.delete();
        });
        return true;
      });
  }

  deactivateLeaveRequest(leaveRequestId) {
    return this.firestore.collection('leaveRequests').doc(leaveRequestId).set(
      {
        status: 'deleted',
      },
      { merge: true }
    );
  }

  activateLeaveRequest(leaveRequestId) {
    return this.firestore.collection('leaveRequests').doc(leaveRequestId).set(
      {
        status: 'active',
      },
      { merge: true }
    );
  }

  updateLeaveRequest(leaveRequestId, data) {
    return this.firestore
      .collection('leaveRequests')
      .doc(leaveRequestId)
      .set(
        {
          ...data,
          updated: this.fieldValue.serverTimestamp(),
        },
        { merge: true }
      );
  }

  approveLeaveRequest(leaveRequestId, auth) {
    let user = null;

    if (auth && auth.currentUser) {
      user = auth.currentUser;
    }

    if (!user) {
      const cachedUserJson = localStorage.getItem('authUser');
      if (!cachedUserJson) {
        throw new Error('no auth user set and no user info cached');
      }

      const cachedUser = JSON.parse(cachedUserJson);
      if (!cachedUser) {
        throw new Error('no auth user set and no user info cached');
      }

      user = cachedUser;
    }

    return this.updateLeaveRequest(leaveRequestId, {
      approvedStatus: 'approved',
      approvedByUid: user.uid,
      approvedByEmail: user.email,
      approvedByName: user.displayName,
    });
  }

  rejectLeaveRequest(leaveRequestId, auth) {
    let user = null;

    if (auth && auth.currentUser) {
      user = auth.currentUser;
    }

    if (!user) {
      const cachedUserJson = localStorage.getItem('authUser');
      if (!cachedUserJson) {
        throw new Error('no auth user set and no user info cached');
      }

      const cachedUser = JSON.parse(cachedUserJson);
      if (!cachedUser) {
        throw new Error('no auth user set and no user info cached');
      }

      user = cachedUser;
    }

    return this.updateLeaveRequest(leaveRequestId, {
      approvedStatus: 'rejected',
      approvedByUid: user.uid,
      approvedByEmail: user.email,
      approvedByName: user.displayName,
    });
  }
}

export default FirestoreLeaveRequestApi;
