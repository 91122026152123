import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withFirebase } from "../Firebase";
import { compose } from "recompose";
import { withAuthorization } from "../Session";
import { find, map } from "ramda";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel, getPaginationRowModel, getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { Link } from "react-router-dom";
import { dateFormat, getActionButtons, getApprovalText } from "./helpers";
import DebouncedInput from "./DebouncedInput";

const LeaveRequestAdminTable = ( { requests, filterValue }) => {
  const [columnFilters, setColumnFilters] = React.useState([])
  const [globalFilter, setGlobalFilter] = React.useState('')

  useEffect(() => {
    if(filterValue) {
      setGlobalFilter(String(filterValue));
    }
    // We only want to run this on component mount, as such we do not want to add a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columnHelper = createColumnHelper();
  const columns = React.useMemo(() => [
    columnHelper.accessor('leaveTypeName', {
      cell: info => {
        const leaveType = info.row.original.leaveType;
        return (
          <i className={leaveType.icon} style={{ paddingLeft: '1em' }} title={leaveType.name ?? ""}/>
        )
      },
      header: () => <span>Type</span>,
    }),
    columnHelper.accessor('employeeName', {
      cell: info => {
        const employee = info.row.original.employee;
        return (<Link to={`/users/edit/${employee.uid}`}>{employee.displayName}</Link>)
      },
      header: () => <span>Ansatt</span>
    }),
    columnHelper.accessor('description', {
      cell: info => {
        return info.getValue()
      },
      header: () => <span>Forklaring</span>
    }),
    columnHelper.accessor('dateStart', {
      cell: info => {
        return dateFormat.format(info.getValue().toDate());
      },
      header: () => <span>Fra</span>
    }),
    columnHelper.accessor('dateEnd', {
      cell: info => {
        return dateFormat.format(info.getValue().toDate());
      },
      header: () => <span>Til</span>
    }),
    columnHelper.accessor('longevity', {
      cell: info => {
        return info.getValue()
      },
      header: () => <span>Varighet</span>
    }),
    columnHelper.accessor('approvedStatusText', {
      cell: info =>{
        return info.getValue()
      },
      header: () => <span>Status</span>
    }),
    columnHelper.accessor('actions', {
      cell: info => {
        return info.getValue()
      },
      header: () => <span>Handlinger</span>
    }),
  ], [columnHelper]);

  const table = useReactTable({
    data: requests,
    columns,
    initialState: {
      sorting: [{id: 'approvedStatusText', desc: true}, {id: 'dateStart', desc: true}],
      pagination: {
        pageIndex: 1,
        pageSize: 20,
      }
    },
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnFilters,
      globalFilter,
    },
    autoResetPageIndex: false,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <div>
      <DebouncedInput
        value={globalFilter ?? ''}
        onChange={value => {
          table.resetPageIndex(true);
          setGlobalFilter(String(value))
        }}
        className="p-2 font-lg border border-block"
        placeholder="Søk i alle kolonner..."
      />
      <table>
        <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <th key={header.id} style={{paddingRight:"1em", paddingTop:"1em", paddingBottom:"1em"}}>
                {header.isPlaceholder
                  ? null
                  : (
                  <>
                    <div
                      style = {{cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '1em'}}
                      {...{
                        onClick: header.column.getToggleSortingHandler(),
                      }}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <i className={'fas fa-sort-up'} />,
                        desc: <i className={'fas fa-sort-down'} />,
                      }[header.column.getIsSorted()] ?? <i className={'fas fa-sort'} />}
                    </div>
                  </>
                  )}
              </th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody>
        {table.getRowModel().rows.map(row => (
          <tr
            key={row.id}
            className={(() => {
              switch (row.original.approvedStatus) {
                case 'approved':
                  return 'table-success';
                case 'rejected':
                  return 'table-danger';
                case 'pending':
                  return 'table-warning';
                default:
                  return '';
              }
            })() + " small"}
            style={{
              textDecoration: row.original.status === "deleted" ? "line-through" : null,
            }}
          >
            {row.getVisibleCells().map(cell => (
              <td key={cell.id} style={{paddingRight:"1em", paddingTop:"1em", paddingBottom:"1em"}}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
        </tbody>
      </table>
      <div className="h-2" />
      <div style={{display: 'flex', flexFlow: 'column',alignItems: 'center', gap: '0.5em', paddingTop: '1em', paddingBottom: '3em'}}>
        <div style={{display: 'flex', alignItems: 'center', gap: '0.5em'}}>
          <button
            className="border rounded p-1"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>
        </div>
        <div style={{display: "flex", alignItems: "center", gap: '1em'}}>
          <div>
            Side {table.getState().pagination.pageIndex + 1} av {table.getPageCount()}
          </div>
        </div>
        <div style={{display: "flex", alignItems: "center", gap: '1em'}} >
          Gå til side:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              table.setPageIndex(page)
            }}
            style={{maxWidth: '5em'}}
            className="border p-1 rounded w-16"
          />
        <select
          value={table.getState().pagination.pageSize}
          onChange={e => {
            table.setPageSize(Number(e.target.value))
          }}
          className="border p-1 rounded w-16"
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Vis {pageSize}
            </option>
          ))}
        </select>
        </div>
      </div>
    </div>
  )
}

const composeLeaveRequestList = (leaveRequests, users, leaveTypes) => {
  return map(lr => {
    const employee = find(u => u.uid === lr.userUid, users);
    const leaveType = find(lt => lt.uid === lr.leaveTypeUid, leaveTypes);

    return {
      employeeId: employee.uid,
      employeeName: employee.displayName,
      employee: employee,
      description: lr.description,
      dateStart: lr.dateStart,
      dateEnd: lr.dateEnd,
      status: lr.status,
      approvedStatus: lr.approvedStatus,
      approvedStatusText: getApprovalText(lr.approvedStatus),
      longevity: lr.workDays + ' (' + lr.totalHours + ' t)',
      id: lr.uid,
      leaveTypeName: leaveType.name,
      leaveType,
      created: lr.created,
      actions: getActionButtons(lr.approvedStatus, lr.status, lr.uid)
    }
  }, leaveRequests)
}

const LeaveRequestAdminTableView = ( { firebase, onlyPending, filter }) => {

  const [leaveRequests, setLeaveRequests] = useState([]);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [users, setUsers] = useState([]);

  // fetching all the data.
  useEffect(() => {
    //fetching all leaveRequests
    let leaveRequestQuery = firebase.leaveRequests().orderBy('dateStart');

    if(onlyPending) {
      leaveRequestQuery = leaveRequestQuery.where('approvedStatus', '==', 'pending')
    }

    const leaveRequestSubscription = leaveRequestQuery.onSnapshot((querySnapshot) => {
      const docs = map(doc => {
        return ({ ...doc.data(), uid: doc.id} )
      }, querySnapshot.docs)
      setLeaveRequests(docs);
    })


    //fetching all users
    firebase.usersFirestore().onSnapshot(querySnapshot => {
      const docs = map(doc => {
        return ({...doc.data(), uid: doc.id})
      }, querySnapshot.docs)
      setUsers(docs);
    })

    //fetching all leaveTypes
    firebase.leaveTypes().get().then(collection => {
      const lt = map(doc => {
        return ({...doc.data(), uid: doc.id})
      }, collection.docs)
      setLeaveTypes(lt)
    })

    return leaveRequestSubscription; //unsubscribe
    // Should only be run on component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {leaveRequests.length < 1 || users < 1 || leaveTypes < 1? (
        <div>Ingen åpne fraværsforespørsler</div>
      ) : (
        <div>
          <LeaveRequestAdminTable requests={composeLeaveRequestList(leaveRequests, users, leaveTypes)} filterValue={filter}/>
        </div>
      )}
    </>
  );
};

LeaveRequestAdminTableView.propTypes = {
  firebase: PropTypes.any,
  onlyPending: PropTypes.bool,
  filter: PropTypes.any,
}

const condition = (authUser) => !!authUser;
export default compose(withAuthorization(condition), withFirebase)(LeaveRequestAdminTableView);
