import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';

import UserStats from '../Data/UserStats';

class DataTest extends Component {
  componentDidMount() {
    const userStats = new UserStats();

    console.table(userStats);

    // TODO: add leaveRequests with leaveProfiles
    this.props.firebase.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.props.firebase
          .userGetLeaveProfile(authUser.uid)
          .then((leaveProfile) => {
            this.props.firebase
              .leaveRequestsWithType(authUser.uid, 2020)
              .then((leaveRequests) => {
                //console.log("leaveRequestsWithType");
                //console.log(leaveRequests);
                userStats
                  .setLeaveProfile(leaveProfile)
                  .setLeaveRequestsWithType(leaveRequests);

                console.table(userStats.stats);
                console.log(
                  'feriedager brukt: ',
                  userStats.getFerieDagerBrukt()
                );
                console.log(
                  'egenmeldingsdager brukt: ',
                  userStats.getEgenmeldingsDagerBrukt()
                );

                console.log(
                  'omsorgsdager brukt: ',
                  userStats.getOmsorgsDagerBrukt()
                );
                console.log(
                  'getFerieTimerTilgjengelig: ',
                  userStats.getFerieTimerTilgjengelig()
                );
                console.log(
                  'getEgenmeldingsTimerTilgjengelig: ',
                  userStats.getEgenmeldingsTimerTilgjengelig()
                );
                console.log(
                  'getOmsorgsTimerTilgjengelig: ',
                  userStats.getOmsorgsTimerTilgjengelig()
                );
                console.log(
                  'getFerieTimerBruktProsent: ',
                  userStats.getFerieTimerBruktProsent(),
                  '%'
                );
                console.log(
                  'getEgenmeldingsTimerBruktProsent: ',
                  userStats.getEgenmeldingsTimerBruktProsent(),
                  '%'
                );
                console.log(
                  'getOmsorgdTimerBruktProsent: ',
                  userStats.getOmsorgsTimerBruktProsent(),
                  '%'
                );
                console.log(
                  'getRemainingFerieTimerIfApproved(5)',
                  userStats.getRemainingFerieTimerIfApproved(12.5)
                );
                console.log(
                  'getRemainingEgenmeldingsTimerIfApproved(1.25)',
                  userStats.getRemainingEgenmeldingsTimerIfApproved(1.25)
                );
                console.log(
                  'getRemainingOmsorgsTimerIfApproved(2.5)',
                  userStats.getRemainingOmsorgsTimerIfApproved(2.5)
                );
              });
          });
      }
    });
  }
  render() {
    return (
      <div>
        <h3>Data Test</h3>
      </div>
    );
  }
}

export default compose(
  //withAuthorization(condition),
  withFirebase
)(DataTest);
