import React from 'react';

function getRemainingHoursIfApproved(
  leaveRequestHours,
  availableHours,
  usedHours
) {
  return availableHours - (leaveRequestHours + usedHours);
}

function getRemainingDaysIfApproved(
  leaveRequestHours,
  availableHours,
  usedHours,
  hoursPerDay
) {
  return (availableHours - (leaveRequestHours + usedHours)) / hoursPerDay;
}

const UserStatsBox = (props) => (
  <div>
    <h3>Stats</h3>
    {props.leaveType.deductedLeave && (
      <div>
        <h4>Ferie</h4>
        For {props.userStats.year}: {props.userStats.totalFerieDager} dager (
        {props.userStats.totalFerieTimer} timer)
        <hr />
        {props.userStats.ferieDagerBrukt} feriedager brukt (
        {props.userStats.ferieTimerBrukt}) timer
        <hr />
        {props.userStats.ferieDagerRemaining} dager gjenstående (
        {props.userStats.ferieTimerBrukt} timer)
        <hr />
        {getRemainingDaysIfApproved(
          props.leaveRequest.totalHours,
          props.userStats.totalFerieTimer,
          props.userStats.ferieTimerBrukt,
          props.userStats.hoursPerDay
        )}{' '}
        days remaining if approved <br />(
        {getRemainingHoursIfApproved(
          props.leaveRequest.totalHours,
          props.userStats.totalFerieTimer,
          props.userStats.ferieTimerBrukt
        )}{' '}
        timer )
      </div>
    )}
    {props.leaveType.deductedEgenmelding && <div>Egenmelding</div>}
    {props.leaveType.deductedOmsorgsdager && <div>Omsorgsdager</div>}
  </div>
);

export default UserStatsBox;
