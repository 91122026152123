import React from "react";
import { Link } from "react-router-dom";
import ApproveRejectButton from "./ApproveRejectButton";

export const dateFormat = new Intl.DateTimeFormat('nb-NO', {
  year: 'numeric',
  month: 'long',
  day: '2-digit',
})

export const getApprovalText = (status) => {
  if (status === 'pending') {
    return 'Venter';
  }
  else if (status === 'approved') {
    return 'Godkjent';
  }
  return 'Avslått'
}

export const getActionButtons = (approvalStatus, requestStatus, requestId) => {
  return (
    <>
      <Link
        to={`/leaverequests/show/${requestId}`}
        className="btn btn-sm btn-primary mr-2 mb-1"
        title={'Vis søknad'}
      >
        <i className="fas fa-eye"/>
      </Link>

      {approvalStatus !== 'rejected' && requestStatus === 'active' && (
        <ApproveRejectButton
          type="reject"
          className="mr-2 mb-1"
          leaveRequestUid={requestId}
        />
      )}
      {approvalStatus !== 'approved' && requestStatus === 'active' && (
        <ApproveRejectButton
          type="approve"
          className="mr-2 mb-1 text-nowrap"
          leaveRequestUid={requestId}
        />
      )}
      {requestStatus !== 'active' && (
        <ApproveRejectButton
          type="activate"
          className="mr-2 mb-1 text-nowrap"
          leaveRequestUid={requestId}
        />
      )}
    </>
  )
}
