// https://www.robinwieruch.de/complete-firebase-authentication-react-tutorial
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Navigation from './Navigation';
import SignInPage from '../SignIn';

import HomePage from '../Home';
import AccountPage from '../Account';
import SummaryPage from '../Summary';

import AdminPage from '../Admin';
import EditUserPage from '../Admin/EditUserPage';

import LeaveTypesPage from '../LeaveTypes';
import EditLeaveTypePage from '../LeaveTypes/EditLeaveTypePage';

import LeaveProfilesPage from '../LeaveProfiles';
import EditLeaveProfilePage from '../LeaveProfiles/EditLeaveProfilePage';

import DepartmentPage from '../Department';
import EditDepartmentPage from '../Department/EditDepartmentPage';

import BankHolidaysPage from '../BankHolidays';

import LeaveRequest from '../LeaveRequest';
import LeaveRequestShowPage from '../LeaveRequest/LeaveRequestShowPage';
import LeaveRequestPage from '../Admin/LeaveRequestPage';
import LeaveRequestAction from '../LeaveRequestAction';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';

import ReportsPage from '../Reports';

const App = () => (
  <Router>
    <div className="container">
      <Navigation />
      <Routes>

        <Route path={ROUTES.SIGN_IN} element={<SignInPage />} />
        <Route exact path={ROUTES.LANDING} element={<HomePage />} />
        <Route path={ROUTES.HOME} element={<HomePage />} />
        <Route path={ROUTES.ACCOUNT} element={<AccountPage />} />

        <Route path={ROUTES.ADMIN} element={<AdminPage />} />
        <Route path={ROUTES.SUMMARY} element={<SummaryPage />} />
        <Route exact path={ROUTES.USERS_EDIT} element={<EditUserPage />} />

        <Route exact path={ROUTES.LEAVETYPES} element={<LeaveTypesPage />} />
        <Route path={ROUTES.LEAVETYPES_EDIT} element={<EditLeaveTypePage />} />

        <Route exact path={ROUTES.LEAVEPROFILES} element={<LeaveProfilesPage />} />
        <Route
          path={ROUTES.LEAVEPROFILES_EDIT}
          element={<EditLeaveProfilePage />}
        />

        <Route exact path={ROUTES.DEPARTMENT} element={<DepartmentPage />} />
        <Route path={ROUTES.DEPARTMENT_EDIT} element={<EditDepartmentPage />} />

        <Route exact path={ROUTES.BANKHOLIDAYS} element={<BankHolidaysPage />} />

        <Route exact path={ROUTES.LEAVEREQUESTS} element={<LeaveRequestPage />} />
        <Route exact path={ROUTES.LEAVEREQUESTS_NEW} element={<LeaveRequest />} />
        <Route
          exact
          path={ROUTES.LEAVEREQUESTS_SHOW}
          element={<LeaveRequestShowPage />}
        />
        <Route
          exact
          path={ROUTES.LEAVEREQUEST_ACTION}
          element={<LeaveRequestAction />}
        />

        <Route exact path={ROUTES.REPORTS} element={<ReportsPage />} />
      </Routes>
    </div>
  </Router>
);

export default withAuthentication(App);
