import React, { Component } from 'react';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';

/**
 * Props
 *  - type: approve|reject
 *  - leaveRequestUid: leaveRequest.uid
 * - className: additional css classes
 *  - callback(leaveRequestUid, status): callback function when button clicked
 */
class ApproveRejectButton extends Component {
  changeStatus(status) {
    if (status === 'reject') {
      this.props.firebase.leaveRequestReject(this.props.leaveRequestUid);
    } else if (status === 'approve') {
      this.props.firebase.leaveRequestApprove(this.props.leaveRequestUid);
    } else if (status === 'activate') {
      this.props.firebase.leaveRequestActivate(this.props.leaveRequestUid);
    }

    if (this.props.callback) {
      this.props.callback(this.props.leaveRequestUid, 'approved');
    }
  }

  render() {
    return (
      <>
        {this.props.type === 'reject' && (
          <button
            className={`btn btn-sm btn-danger ${this.props.className}`}
            onClick={() => this.changeStatus('reject')}
            title={'Avslå'}
          >
            <i className="fas fa-trash-alt"/> Avslå
          </button>
        )}
        {this.props.type === 'approve' && (
          <button
            className={`btn btn-sm btn-success ${this.props.className}`}
            onClick={() => this.changeStatus('approve')}
            title={'Godkjenn'}
          >
            <i className="fas fa-check"/> Godkjenn
          </button>
        )}
        {this.props.type === 'activate' && (
          <button
            className={`btn btn-sm btn-warning ${this.props.className}`}
            onClick={() => this.changeStatus('activate')}
            title={'Aktiver'}
          >
            <i className="fas fa-exclamation-triangle"/> Aktiver
          </button>
        )}
      </>
    );
  }
}

export default compose(withFirebase)(ApproveRejectButton);
