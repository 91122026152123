import React from "react";
import { useNavigate } from "react-router-dom";

const LeaveRequestBackButton = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <button
        className={`btn btn-sm btn-warning ${props.className}`}
        onClick={() => navigate(-1)}>
        <i className="fas fa-backward"/> Tilbake
      </button>
    </>
  )
}

export default LeaveRequestBackButton;

