import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';

const LeaveRequestAction = ({
  leaveRequestId,
  leaveRequestAnswer,
  onLeaveApproved,
  onLeaveRejected,
}) => {
  if (!leaveRequestId) {
    return <p>No leaveRequestId</p>;
  }

  if (!leaveRequestAnswer) {
    return <p>No leaveRequestAnswer</p>;
  }

  switch (leaveRequestAnswer) {
    case 'approve':
      onLeaveApproved(leaveRequestId);
      return <p>Request approved</p>;
    case 'reject':
      onLeaveRejected(leaveRequestId);
      return <p>Request rejected</p>;
    default:
      return <p>Invalid leaveRequestAnswer</p>;
  }
};

LeaveRequestAction.propTypes = {
  leaveRequestAnswer: PropTypes.string.isRequired,
  leaveRequestId: PropTypes.string.isRequired,
  onLeaveApproved: PropTypes.func.isRequired,
  onLeaveRejected: PropTypes.func.isRequired,
};

export default compose(withFirebase)(LeaveRequestAction);
