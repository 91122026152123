class FirestoreHolidayApi {
  constructor(firestore, fieldValue) {
    this.firestore = firestore;
    // https://firebase.google.com/docs/reference/js/firebase.firestore.FieldValue#servertimestamp
    this.fieldValue = fieldValue;
  }

  getHolidaysRefs() {
    return this.firestore.collection('bankHolidays');
  }

  getHolidayRef(holidayId) {
    return this.firestore.doc(`bankHolidays/${holidayId}`);
  }

  getActiveHolidays() {
    return this.getHolidaysRefs()
      .where('isActive', '==', true)
      .get()
      .then(function (querySnapshot) {
        let bankHolidays = [];
        querySnapshot.forEach(function (doc) {
          bankHolidays.push({ ...doc.data(), uid: doc.id });
        });
        return bankHolidays;
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
      });
  }

  createHolidayFromApi(holiday, auth) {
    const holidayDate = new Date(holiday.date);
    return this.firestore
      .collection('bankHolidays')
      .doc(holidayDate.toDateString())
      .set(
        {
          description: holiday.description,
          date: holidayDate,
          isActive: true,
          createdUserId: auth.currentUser.uid,
          createdEmail: auth.currentUser.email,
          created: this.fieldValue.serverTimestamp(),
          updated: null,
        },
        { merge: true }
      );
  }

  createHolidayFromInput(holiday, auth) {
    return this.firestore
      .collection('bankHolidays')
      .doc(holiday.date.toDateString())
      .set(
        {
          ...holiday,
          isActive: true,
          createdUserId: auth.currentUser.uid,
          createdEmail: auth.currentUser.email,
          created: this.fieldValue.serverTimestamp(),
          updated: null,
        },
        { merge: true }
      );
  }

  setActivationStatus(holidayId, active) {
    return this.firestore
      .collection('bankHolidays')
      .doc(holidayId)
      .set(
        {
          isActive: active,
          updated: this.fieldValue.serverTimestamp(),
        },
        { merge: true });
  }

  deactivateHoliday(holidayId) {
    return this.setActivationStatus(holidayId, false)
  }

  activateHoliday(holidayId) {
    return this.setActivationStatus(holidayId, true)
  }

  deleteHoliday(holidayId) {
    return this.firestore.collection('bankHolidays').doc(holidayId).delete();
  }
}

export default FirestoreHolidayApi;
