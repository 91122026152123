import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const StatsBox = (props) => (
  <div className="text-right">
    <h3>{props.stats.year}</h3>
    <h3>
      {props.selectedLeaveType.icon && (
        <i className={props.selectedLeaveType.icon + ' mr-2'}></i>
      )}
      {props.selectedLeaveType.name}
    </h3>
    <p>
      Krever godkjenning:{' '}
      <strong>{props.selectedLeaveType.requiresApproval ? 'JA' : 'NEI'}</strong>
    </p>
    {props.leaveProfile && (
      <>
        {props.selectedLeaveType.deductedLeave && (
          <>
            <div>
              Ferie per år: {props.leaveProfile.ferieDager} dager (
              {props.stats.ferieTimerTilgjengelig} timer)
            </div>
            <div>
              Ferie brukt: {props.stats.ferieDagerBrukt} dager (
              {props.stats.ferieTimerBrukt} timer)
            </div>

            <div className="d-flex flex-row-reverse">
              <div className="w40">
                <CircularProgressbar
                  value={props.stats.ferieTimerBruktProsent}
                  text={`${parseInt(props.stats.ferieTimerBruktProsent)}%`}
                />
              </div>
            </div>
            <hr />
            <div>
              Feriedager tilgjengelig (dersom godkjent):{' '}
              {props.remainingFeriedagerIfApproved}
            </div>
          </>
        )}
        {props.selectedLeaveType.deductedEgenmelding && (
          <>
            <div>
              Egenmeldinger per år: {props.leaveProfile.egenmeldingsDager} dager
              ({props.stats.egenmeldingsTimerTilgjengelig} timer)
            </div>
            <div>
              Egenmeldinger brukt: {props.stats.egenmeldingsDagerBrukt} dager (
              {props.stats.egenmeldingsTimerBrukt} timer)
            </div>

            <div className="d-flex flex-row-reverse">
              <div className="w40">
                <CircularProgressbar
                  value={props.stats.egenmeldingsTimerBruktProsent}
                  text={`${parseInt(
                    props.stats.egenmeldingsTimerBruktProsent
                  )}%`}
                />
              </div>
            </div>
            <hr />
            <div>
              Egenmeldingsdager tilgjengelig (dersom godkjent):{' '}
              {props.remainingEgenmeldingsDagerIfApproved}{' '}
            </div>
          </>
        )}
        {props.selectedLeaveType.deductedOmsorgsdager && (
          <>
            <div>
              Omsorgsdager per år: {props.leaveProfile.omsorgsDager} (
              {props.stats.omsorgsTimerTilgjengelig} timer)
            </div>
            <div>
              Omsorgsdager brukt: {props.stats.omsorgsDagerBrukt} (
              {props.stats.omsorgsTimerBrukt} timer)
            </div>
            <div className="d-flex flex-row-reverse">
              <div className="w40">
                <CircularProgressbar
                  value={props.stats.omsorgsTimerBruktProsent}
                  text={`${parseInt(props.stats.omsorgsTimerBruktProsent)}%`}
                />
              </div>
            </div>
            <hr />
            <div>
              Omsorgsdager tilgjengelig (dersom godkjent):{' '}
              {props.remainingOmsorgsdagerDagerIfApproved}
            </div>
          </>
        )}
        <hr />
        <h4>DEBUG:</h4>
        Total Selected Days: {props.totalDays}
        <br />
        Total Selected Work Days: {props.totalWorkDays}
        <br />
        Total Selected Hours: {props.totalWorkHours} <br />
      </>
    )}
  </div>
);

export default StatsBox;
