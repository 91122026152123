export const LANDING = '/';

export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';

export const ADMIN = '/admin';
export const USERS_EDIT = '/users/edit/:uid';

export const SUMMARY = '/summary';

export const LEAVETYPES = '/leavetypes';
export const LEAVETYPES_EDIT = '/leavetypes/edit/:uid';

export const LEAVEPROFILES = '/leaveprofiles';
export const LEAVEPROFILES_EDIT = '/leaveprofiles/edit/:uid';

export const DEPARTMENT = '/department';
export const DEPARTMENT_EDIT = '/department/edit/:uid';

export const BANKHOLIDAYS = '/bankholidays';
export const BANKHOLIDAYS_EDIT = '/bankholidays/edit/:uid';

export const LEAVEREQUESTS = '/leaverequests';
export const LEAVEREQUESTS_NEW = '/leaverequests/new';
export const LEAVEREQUESTS_SHOW = '/leaverequests/show/:uid';
export const LEAVEREQUEST_ACTION = '/leaverequest/action';

export const REPORTS = '/reports';

export default {
  LANDING,
  SIGN_IN,
  HOME,
  ACCOUNT,
  ADMIN,
  USERS_EDIT,
  SUMMARY,
  LEAVETYPES,
  LEAVETYPES_EDIT,
  LEAVEPROFILES,
  LEAVEPROFILES_EDIT,
  DEPARTMENT,
  DEPARTMENT_EDIT,
  BANKHOLIDAYS,
  BANKHOLIDAYS_EDIT,
  LEAVEREQUESTS,
  LEAVEREQUESTS_NEW,
  LEAVEREQUESTS_SHOW,
  LEAVEREQUEST_ACTION,
  REPORTS,
};
