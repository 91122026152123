import React from 'react';
import { withFirebase } from '../Firebase';
import './Legend.css';

class Legend extends React.Component {
    constructor(props) {
        super(props);
        this.state = {leaveTypes: []};

        this.props.firebase
            .leaveTypes()
            .get()
            .then((collection) => {
                let leaveTypes = [];
                collection.forEach(function (doc) {
                    leaveTypes.push({...doc.data(), uid: doc.id});
                });
                this.setState({
                    leaveTypes
                });
            })
    }

    render() {
        let items = [];
        this.state.leaveTypes.forEach((item) => {
            const style = {'backgroundColor': item.color}
            items.push(
                <div className="legend-item" key={item.uid}>
                    <div className="legend-color" style={style}/>
                    <div className="legend-text">{item.name}</div>
                </div>);
        })
        return <div className="legend">{items}</div>;
    }
}

export default withFirebase(Legend);
