import React, {Component} from 'react';
import {compose} from 'recompose';
import moment from 'moment';
import nb from 'date-fns/locale/nb';
import DatePicker, {registerLocale} from "react-datepicker";

import {withFirebase} from '../Firebase';
import {withAuthentication} from '../Session';
import * as ROUTES from '../../constants/routes';

import StatsBox from './StatsBox';
import UserStats from '../Data/UserStats';
import withNavigate from "../App/withNavigate";

const INITIAL_STATE = {
  authUser: null,
  leaveProfile: null,
  description: '',
  status: 'active',
  dateFrom: "",
  dateTo: "",
  leaveTypeUid: null,
  approvedStatus: 'pending',
  error: '',
  loading: true,
  hoursPerDay: 7.5, // default value - will be updated when leaveProfile is loaded
  requestDays: [],
  bankHolidays: [],
  stats: null,
};

class NewLeaveRequestFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {...INITIAL_STATE};

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    registerLocale('nb', nb);

    this.userStats = new UserStats();

    this.getLeaveTypes().then((leaveTypes) => {
      this.setState({
        leaveTypes: leaveTypes,
      });
    });

    this.props.firebase.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.setState({authUser});
        this.props.firebase
          .userGetLeaveProfile(authUser.uid)
          .then((leaveProfile) => {
            this.setState({
              leaveProfile,
              hoursPerDay: parseFloat(leaveProfile.hoursPerDay),
            });
          });

        this.props.firebase
          .userCalculateYearStats(authUser.uid)
          .then((stats) => {
            this.setState({stats: stats});
          });

        this.props.firebase
          .bankHolidaysCollection()
          .then((bankHolidays) => {
            this.setState({bankHolidays: bankHolidays});
          })
      }
    });
  }

  onSubmit = (event) => {
    const {
      description,
      status,
      leaveTypeUid,
      dateFrom,
      dateTo,
      selectedLeaveType,
      requestDays,
    } = this.state;

    let approvedStatus = this.state.approvedStatus;

    event.preventDefault();

    if (!leaveTypeUid) {
      this.setState({error: 'Leave type not selected'});
      return;
    }

    if (dateFrom.getFullYear() !== dateTo.getFullYear()) {
      this.setState({error: 'Dates must be in the same year.'});
      return;
    }

    if (!selectedLeaveType.requiresApproval) {
      approvedStatus = 'approved';
    }

    const ferieDager = moment(dateTo).diff(moment(dateFrom), 'days') + 1;

    this.props.firebase
      .leaveRequestAdd(
        {
          description,
          status,
          ferieDager,
          dateStart: dateFrom,
          dateEnd: dateTo,
          leaveTypeUid,
          approvedStatus,
          workDays: this.getTotalWorkDays(),
          totalDays: this.getTotalDays(),
          totalHours: this.getTotalWorkHours(),
        },
        requestDays // save requestDays as sub-collection
      )
      .then(() => {
        this.setState({...INITIAL_STATE});
        this.props.navigate(ROUTES.ACCOUNT);
      });
  };

  getLeaveTypes = () => {
    return this.props.firebase
      .leaveTypes()
      .get()
      .then((collection) => {
        let leaveTypes = [];

        collection.forEach(function (doc) {
          leaveTypes.push({...doc.data(), uid: doc.id});
        });
        return leaveTypes;
      });
  };

  getTotalDays = () => {
    const {requestDays} = this.state;

    let totalDays = 0;
    Object.keys(requestDays).map((key) => {
      return totalDays++;
    });

    return totalDays;
  };

  getBankHoliday = (date) => {
    const {bankHolidays} = this.state;
    return bankHolidays.find(
      (bankHoliday) => bankHoliday.uid === date.toDateString()
    )
  }

  getTotalWorkDays = () => {
    const {requestDays} = this.state;

    let totalDays = 0;
    Object.keys(requestDays).map((key) => {
      if (!this.isWeekendOrHoliday(key)) {
        totalDays++;
      }

      return totalDays;
    });

    return totalDays;
  };

  getTotalWorkHours = () => {
    const {requestDays} = this.state;

    let totalhours = 0;
    Object.keys(requestDays).map((key) => {
      if (!requestDays[key].isWeekend && requestDays[key].hours) {
        totalhours += parseFloat(requestDays[key].hours);
      }
      return null;
    });

    return totalhours;
  };

  getRemainingFeriedagerIfApproved() {
    return (this.state.stats.ferieTimerTilgjengelig -
      (this.state.stats.ferieTimerBrukt + this.getTotalWorkHours())) /
      this.state.hoursPerDay;
  }

  getRemainingEgenmeldingsDagerIfApproved() {
    return (this.state.stats.egenmeldingsTimerTilgjengelig -
      (this.state.stats.egenmeldingsTimerBrukt + this.getTotalWorkHours())) /
      this.state.hoursPerDay;
  }

  getRemainingOmsorgsdagerDagerIfApproved() {
    return (this.state.stats.omsorgsTimerTilgjengelig -
      (this.state.stats.omsorgsTimerBrukt + this.getTotalWorkHours())) /
      this.state.hoursPerDay;
  }

  onChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
  };

  onInitialLeaveSelection = (name, value) => {
    let event = {
      target: {
        name: name,
        value: value,
      }
    };
    this.onLeaveTypeChange(event);
  }

  onLeaveTypeChange = (event) => {
    const {leaveTypes} = this.state;
    let selectedLeaveType = null;

    leaveTypes.map((item) => {
      if (item.uid === event.target.value) {
        selectedLeaveType = item;
      }
      return null;
    });

    this.setState({
      [event.target.name]: event.target.value,
      selectedLeaveType,
    });
  };

  onDayListChange = (event) => {
    const {requestDays} = this.state;
    if (!event.target.value) {
      requestDays[event.target.name].hours = '';
    } else if (event.target.value > this.state.hoursPerDay) {
      requestDays[event.target.name].hours = this.state.hoursPerDay;
    } else {
      requestDays[event.target.name].hours = parseFloat(event.target.value);
    }

    this.setState({requestDays});
  };

  onHalfDay = (key) => {
    const {requestDays, hoursPerDay} = this.state;
    requestDays[key].hours = hoursPerDay / 2;
    this.setState({requestDays});
  };

  onWholeDay = (key) => {
    const {requestDays, hoursPerDay} = this.state;

    requestDays[key].hours = hoursPerDay;
    this.setState({requestDays});
  };

  isWeekendOrHoliday = (key) => {
    const {requestDays} = this.state;
    return requestDays[key].isWeekend || requestDays[key].isBankHoliday;
  };

  handleDateChange = async () => {
    const {dateFrom, dateTo, bankHolidays} = this.state;
    if (!(dateFrom instanceof Date && dateTo instanceof Date)) {
      return;
    }

    let isBankHoliday = false;
    const requestDays = {};
    const dayInTicks = 1000 * 60 * 60 * 24;

    let daysBetween = Math.ceil((dateTo.getTime() - dateFrom.getTime()) / dayInTicks);

    for (let i = 0; i <= daysBetween; i++) {
      const requestedDay = new Date(dateFrom.getTime() + i * dayInTicks);
      let dayOfWeek = requestedDay.getDay();
      let isWeekend = dayOfWeek === 6 || dayOfWeek === 0;

      isBankHoliday = bankHolidays.some(
        (bankHoliday) => bankHoliday.uid === requestedDay.toDateString()
      );

      requestDays[requestedDay.toLocaleString()] = {
        day: new Date(requestedDay.getTime()),
        hours: isWeekend || isBankHoliday ? 0 : this.state.hoursPerDay,
        isWeekend,
        isBankHoliday,
      };
    }

    this.setState({
      requestDays,
    });
  }

  handleDateFromChange = async (dateFrom) => {
    this.setState({
      dateFrom,
      requestDays: [],
    }, async () => {
      await this.handleDateChange()
    });
  }

  handleDateToChange = async (dateTo) => {
    this.setState({
      dateTo,
      requestDays: [],
    }, async () => {
      await this.handleDateChange()
    });
  }

  render() {
    const {
      name,
      dateFrom,
      dateTo,
      leaveTypes,
      requestDays,
      error,
      leaveTypeUid,
      selectedLeaveType,
      leaveProfile,
      hoursPerDay,
      stats,
    } = this.state;

    return (
      <div className="row">
        <div className="col-md-8">
          <form onSubmit={this.onSubmit} autoComplete={"off"}>
            <div className="form-group row">
              {!leaveTypeUid && leaveTypes && leaveTypes.map((item) => (
                  <div className="text-center autocollapsegrid">
                    <button
                      onClick={() => this.onInitialLeaveSelection("leaveTypeUid", item.uid)}
                      className={"mb-4"}
                      style={{height: 9 + 'em', width: 10 + 'em'}}
                    >
                        <div className={item.icon} style={{fontSize: 4 + "em"}}/>
                        <br/>
                        {item.name}
                    </button>
                  </div>
              ))}
              {leaveTypeUid && leaveTypes && (
                <div className="col-sm-10">
                  <select
                    name="leaveTypeUid"
                    onChange={this.onLeaveTypeChange}
                    className="form-control"
                    value={leaveTypeUid}
                  >
                    {leaveTypes.map((item) => (
                      <option value={item.uid} key={item.uid}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            {leaveTypeUid && (
              <>
                <div className="form-group row">
                  <label
                    htmlFor="leaveRequestDescription"
                    className="col-sm-2 col-form-label text-right"
                  >
                    Beskrivelse
                  </label>
                  <div className="col-sm-10">
                    <input
                      type="text"
                      name="description"
                      id="leaveRequestDescription"
                      placeholder="Beskrivelse..."
                      className="form-control mb-2 mr-sm-2 "
                      value={name}
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="leaveRequestDateFrom"
                    className="col-sm-2 col-form-label text-right">
                    Fra dato
                  </label>
                  <div className="col-sm-9">
                    <DatePicker
                      selected={dateFrom}
                      selectsStart={true}
                      isClearable={true}
                      className="form-control mb-2 mr-sm-2"
                      onChange={this.handleDateFromChange}
                      id="leaveRequestDateFrom"
                      value={dateFrom}
                      locale="nb"
                      dateFormat="dd.MM.yyyy"
                      placeholderText="Velg fra-dato..."
                      showWeekNumbers={true}
                      weekLabel={"Uke"}
                      maxDate={dateTo}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="leaveRequestDateTo"
                    className="col-sm-2 col-form-label text-right">
                    Til dato
                  </label>
                  <div className="col-sm-9">
                    <DatePicker
                      selected={dateTo}
                      selectsEnd={true}
                      isClearable={true}
                      className="form-control mb-2 mr-sm-2"
                      onChange={this.handleDateToChange}
                      id="leaveRequestDateTo"
                      value={dateTo}
                      locale="nb"
                      dateFormat="dd.MM.yyyy"
                      placeholderText="Velg til-dato..."
                      showWeekNumbers={true}
                      weekLabel={"Uke"}
                      minDate={dateFrom}
                    />
                  </div>
                </div>
              </>
            )}
            {leaveTypeUid && (
              <div className="form-group row">
                <div className="col-sm-10 offset-sm-2">
                  <table className="table table-sm align-middle">
                    <thead>
                    <tr>
                      <th>Dag</th>
                      <th>Timer</th>
                      <th>&nbsp;</th>
                      <th>&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    {requestDays.length === 0 && (
                      <tr>
                        <td colSpan={4}>Ingen dager valgt enda...</td>
                      </tr>
                    )}
                    {requestDays.length !== 0 && Object.keys(requestDays).map((key) => (
                      <tr
                        key={key}
                        className={
                          this.isWeekendOrHoliday(key)
                            ? 'table-danger'
                            : undefined
                        }
                      >
                        <td
                          className="pt-2 pb-2 align-middle"
                          align={"right"}>
                          {' '}
                          {requestDays[key].day.toLocaleDateString("nb", {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                          })}
                        </td>
                        {!this.isWeekendOrHoliday(key) && (
                          <td
                            className="pt-2 pb-2 align-middle"
                          >
                            <input
                              type="number"
                              name={key}
                              step="0.25"
                              value={requestDays[key].hours}
                              className="form-control mr-sm-2 col-sm-4"
                              onChange={this.onDayListChange}
                            />
                          </td>
                        )}
                        {this.isWeekendOrHoliday(key) && (
                          <td
                            colSpan={2}
                            className="pt-2 pb-2 align-middle"
                          >
                            {requestDays[key].isBankHoliday && (
                              this.getBankHoliday(requestDays[key].day).description
                            )}
                          </td>
                        )}
                        {!this.isWeekendOrHoliday(key) && (
                          <td
                            className="pt-2 pb-2 align-middle"
                          >
                            {requestDays[key].hours !== hoursPerDay / 2 && (
                              <button
                                type="button"
                                className="btn btn-sm btn-secondary"
                                onClick={() => this.onHalfDay(key)}
                              >
                                <i className="fas fa-clock"/> Halv dag
                              </button>
                            )}
                            {requestDays[key].hours !== hoursPerDay && (
                              <button
                                type="button"
                                className="btn btn-sm btn-primary"
                                onClick={() => this.onWholeDay(key)}
                              >
                                <i className="fas fa-calendar-day "/> Hel
                                dag
                              </button>
                            )}
                          </td>
                        )}
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {error && (
              <div
                className="alert alert-warning col-sm-6 offset-sm-2"
                role="alert"
              >
                {error}
              </div>
            )}
            {leaveTypeUid && (
              <div className="offset-sm-2">
                <button type="submit" className="btn btn-success mb-2">
                  Legg til <i className="fas fa-plus-circle"/>
                </button>
              </div>
            )}
          </form>
        </div>
        <div className="col-md-4">
          {selectedLeaveType && stats && (
            <div>
              <StatsBox
                selectedLeaveType={selectedLeaveType}
                stats={stats}
                leaveProfile={leaveProfile}
                totalDays={this.getTotalDays()}
                totalWorkDays={this.getTotalWorkDays()}
                totalWorkHours={this.getTotalWorkHours()}
                remainingFeriedagerIfApproved={this.getRemainingFeriedagerIfApproved()}
                remainingEgenmeldingsDagerIfApproved={this.getRemainingEgenmeldingsDagerIfApproved()}
                remainingOmsorgsdagerDagerIfApproved={this.getRemainingOmsorgsdagerDagerIfApproved()}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const NewLeaveRequestForm = compose(
  withNavigate,
  withAuthentication,
  withFirebase
)(NewLeaveRequestFormBase);

export default compose(
  withFirebase
)(NewLeaveRequestForm);
