import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';

class LeaveRequestList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      leaveRequests: [],
      loading: false,
    };
  }

  onDeactivate = (uid) => {
    this.props.firebase
      .leaveRequestDeactivate(uid)
      .then(function () {
        console.log('Document successfully deactivated!');
      })
      .catch(function (error) {
        console.error('Error deactivating document: ', error);
      });
  };

  onDelete = (uid) => {
    this.props.firebase
      .leaveRequestDelete(uid)
      .then(function () {
        console.log('Document successfully deleted!');
      })
      .catch(function (error) {
        console.error('Error deleting document: ', error);
      });
  };

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.leaveTypeCollection().then((leaveTypes) => {
      let query = this.props.firebase
        .leaveRequests()
        .where('status', '==', 'active')
        .orderBy('created', 'desc');

      if (this.props.onlyPending) {
        query = query.where('approvedStatus', '==', 'pending');
      }

      if (this.props.uid) {
        query = query.where('userUid', '==', this.props.uid);
      }

      this.unsubscribe = query.onSnapshot((snapshot) => {
        let leaveRequests = [];

        snapshot.forEach((doc) =>
          leaveRequests.push({
            ...doc.data(),
            uid: doc.id,
            leaveType: leaveTypes[doc.get('leaveTypeUid')] || null,
          })
        );

        this.setState({
          leaveRequests,
          loading: false,
        });
      });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { leaveRequests } = this.state;
    return (
      <>
        <table className="table">
          <thead>
            <tr>
              <th>Beskrivelse</th>
              <th>Fra - Til</th>
              <th>Lengde</th>
              <th>Type</th>
              <th colSpan={2}>Status</th>
            </tr>
          </thead>
          <tbody>
            {leaveRequests.map((item) => (
              <tr
                key={item.uid}
                className={
                  'small ' +
                  (() => {
                    switch (item.approvedStatus) {
                      case 'approved':
                        return 'table-success';
                      case 'rejected':
                        return 'table-danger';
                      case 'pending':
                        return 'table-warning';
                      default:
                        return '';
                    }
                  })()
                }
              >
                <td>{item.description}</td>
                <td style={{ minWidth: 10 + 'em' }}>
                  Fra:{' '}
                  {new Intl.DateTimeFormat('nb-NO', {
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit',
                  }).format(item.dateStart.toDate())}
                  <br />
                  Til:{' '}
                  {new Intl.DateTimeFormat('nb-NO', {
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit',
                  }).format(item.dateEnd.toDate())}
                </td>
                <td>
                  {item.workDays} ({item.totalHours} t)
                </td>
                <td className={'text-nowrap'}>
                  {item.leaveType && (
                    <div>
                      {item.leaveType.icon && (
                        <i className={item.leaveType.icon} />
                      )}{' '}
                      {item.leaveType.name}
                    </div>
                  )}
                  {!item.leaveType && <div> ERROR: Missing Leave Type!!</div>}
                </td>
                <td>
                  {item.approvedStatus === 'pending' && <span>Venter</span>}
                  {item.approvedStatus === 'approved' && <span>Godkjent</span>}
                  {item.approvedStatus === 'rejected' && <span>Avslått</span>}
                </td>
                <td>
                  <i
                    className="fas fa-trash"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (item.approvedStatus === 'pending') {
                        this.onDelete(item.uid);
                      } else {
                        this.onDeactivate(item.uid);
                      }
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }
}

const condition = (authUser) => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase
)(LeaveRequestList);
