import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import withNavigate from "../App/withNavigate";

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  onSubmit = (event) => {
    event.preventDefault();
    const { firebase } = this.props;
    return firebase
      .doSignInWithGoogle()
      .then((socialAuthUser) => {
        if (!socialAuthUser.user.email.endsWith('@apparat.no')) {
          const err = 'Not an apparat.no email';
          this.setState({ error: err });
          return Promise.reject(err);
        }

        if (!socialAuthUser.user.emailVerified) {
          const err = 'Email is not verified';
          this.setState({ error: err });
          return Promise.reject(err);
        }

        return firebase.createUser(socialAuthUser);
      })
      .then(() => {
        this.setState({ error: null });
        this.props.navigate(ROUTES.LANDING);
      })
      .catch((error) => {
        firebase.doSignOut();
        this.setState({ error: error.message ? error.message : error });
      });
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit" className="btn btn-danger">
          Logg inn med Google <i className="fab fa-google"></i>
        </button>
        {error && <p>{error}</p>}
      </form>
    );
  }
}

const SignInGoogle = compose(withNavigate, withFirebase)(SignInGoogleBase);

export default SignInGoogle;
