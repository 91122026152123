import React, { Component } from 'react';
import { compose } from 'recompose';

import { withAuthorization } from '../Session';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import withParams from "../App/withParams";
import withNavigate from "../App/withNavigate";

const EditLeaveProfilePage = () => {
  return (
    <div>
      <div className="offset-sm-2 col-md-6">
        <h4>Rediger Fraværsprofil</h4>
        <hr />
      </div>

      <EditLeaveProfileForm />
    </div>
  );
}

class EditLeaveProfileFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: null,

      leaveProfile: null,
      name: null,
      ferieDager: null,
      egenmeldingsDager: null,
      omsorgsDager: null,
      hoursPerDay: null,
      uid: null,
    };
  }

  componentDidMount() {
    const uid = this.props.params.uid;

    const ref = this.props.firebase.leaveProfile(uid);
    ref.get().then((doc) => {
      if (doc.exists) {
        const leaveProfile = doc.data();

        this.setState({
          key: doc.id,
          uid: uid,
          leaveProfile: leaveProfile,
          name: leaveProfile.name,
          ferieDager: parseInt(leaveProfile.ferieDager) || 0,
          egenmeldingsDager: parseInt(leaveProfile.egenmeldingsDager) || 0,
          omsorgsDager: parseInt(leaveProfile.omsorgsDager) || 0,
          hoursPerDay: parseFloat(leaveProfile.hoursPerDay),
        });
      } else {
        console.log('No such document!');
      }
    });
  }

  onSubmit = (event) => {
    const {
      uid,
      name,
      ferieDager,
      egenmeldingsDager,
      omsorgsDager,
      hoursPerDay,
    } = this.state;

    this.props.firebase
      .leaveProfileUpdate(uid, {
        name,
        ferieDager,
        egenmeldingsDager,
        omsorgsDager,
        hoursPerDay,
      })
      .then(() => {
        //this.setState = { ...INITIAL_STATE };
        // console.log("doc updated");

        // Redirect to /leaveprofiles
        this.props.navigate(ROUTES.LEAVEPROFILES);
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onCheckboxChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  handleDateRangeChange = (dateRange) =>
    this.setState({
      dateRange: dateRange,
    });

  render() {
    const {
      leaveProfile,
      name,
      ferieDager,
      egenmeldingsDager,
      omsorgsDager,
      hoursPerDay,
    } = this.state;

    return (
      <>
        {leaveProfile && (
          <div>
            <form onSubmit={this.onSubmit}>
              <div className="form-group row">
                <label
                  htmlFor="inputName"
                  className="col-sm-2 col-form-label text-right"
                >
                  Navn:
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    name="name"
                    id="inputName"
                    placeholder="Navn..."
                    className="form-control"
                    value={name}
                    onChange={this.onChange}
                    required
                  ></input>
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="inputFeriedager"
                  className="col-sm-2 col-form-label text-right"
                >
                  Feriedager:
                </label>
                <div className="col-sm-2">
                  <input
                    type="number"
                    name="ferieDager"
                    id="inputFeriedager"
                    className="form-control"
                    value={ferieDager}
                    onChange={this.onChange}
                  ></input>
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="inputEgenmeldingsdager"
                  className="col-sm-2 col-form-label text-right"
                >
                  Egenmeldingsdager:
                </label>
                <div className="col-sm-2">
                  <input
                    type="number"
                    name="egenmeldingsDager"
                    id="inputEgenmeldingsdager"
                    className="form-control"
                    value={egenmeldingsDager}
                    onChange={this.onChange}
                  ></input>
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="inputOmsorgsdager"
                  className="col-sm-2 col-form-label text-right"
                >
                  Omsorgsdager:
                </label>
                <div className="col-sm-2">
                  <input
                    type="number"
                    name="omsorgsDager"
                    id="inputOmsorgsdager"
                    className="form-control"
                    value={omsorgsDager}
                    onChange={this.onChange}
                  ></input>
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="inputHoursPerDay"
                  className="col-sm-2 col-form-label text-right"
                >
                  Antall timer per dag:
                </label>
                <div className="col-sm-2">
                  <input
                    type="number"
                    step="0.5"
                    name="hoursPerDay"
                    id="inputHoursPerDay"
                    className="form-control"
                    value={hoursPerDay}
                    onChange={this.onChange}
                  ></input>
                </div>
              </div>

              <div className="offset-sm-2">
                <button type="submit" className="btn btn-primary  mb-2">
                  OPPDATER <i className="far fa-save"></i>
                </button>
              </div>
            </form>
            {/*
                <form className="form" onSubmit={this.onSubmit}>
                    <div className="form-group">
                        <input
                            type="text"
                            name="name"
                            placeholder="Navn..."
                            className="form-control mb-2 mr-sm-2 col-md-6"
                            value={name}
                            onChange={this.onChange}
                            required></input>
                    </div>
                    <div className="form-group">
                        <input
                            type="number"
                            name="ferieDager"
                            className="form-control mb-2 mr-sm-2 col-md-6"
                            value={ferieDager}
                            onChange={this.onChange}
                            ></input>
                        <small id="emailHelp" className="form-text text-muted">
                          Antall feriedager per år
                        </small>
                    </div>
                    <div className="form-group">
                        <input
                            type="number"
                            name="egenmeldingsDager"
                            className="form-control mb-2 mr-sm-2 col-md-6"
                            value={egenmeldingsDager}
                            onChange={this.onChange}
                            ></input>
                        <small id="emailHelp" className="form-text text-muted">
                          Antall egenmeldingsdager per år
                        </small>
                    </div>
                    <div className="form-group">
                        <input
                            type="number"
                            name="omsorgsDager"
                            className="form-control mb-2 mr-sm-2 col-md-6"
                            value={omsorgsDager}
                            onChange={this.onChange}
                            ></input>
                        <small id="emailHelp" className="form-text text-muted">
                          Antall omsorgsdager per år
                        </small>
                    </div>
                    <div className="form-group">
                        <input
                            type="number"
                            step="0.5"
                            name="hoursPerDay"
                            className="form-control mb-2 mr-sm-2 col-md-6"
                            value={hoursPerDay}
                            onChange={this.onChange}
                            ></input>
                        <small id="emailHelp" className="form-text text-muted">
                          Antall timer per dag
                        </small>
                    </div>


                    <div className="form-group">
                        <button type="submit" className="btn btn-primary  mb-2">OPPDATER <i className="far fa-save"></i></button>
                    </div>
              </form>
              */}
          </div>
        )}
      </>
    );
  }
}

const EditLeaveProfileForm = compose(
  withNavigate,
  withParams,
  withFirebase
)(EditLeaveProfileFormBase);
const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(EditLeaveProfilePage);
