import React, { Component } from 'react';
import { compose } from 'recompose';

import moment from 'moment';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import ApproveRejectButton from './ApproveRejectButton';

import UserStats from '../Data/UserStats';
import UserStatsBox from './UserStatsBox';
import LeaveRequestBackButton from "./LeaveRequestBackButton";
import withNavigate from "../App/withNavigate";
import withParams from "../App/withParams";

class LeaveRequestShowPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      leaveRequest: null,
      requestDays: null,
    };
  }

  componentDidMount() {
    this.loadData(this.props.params.uid);

    this.onStatusChanged = this.onStatusChanged.bind(this);
  }

  onStatusChanged(leaveRequestUid, status) {
    //alert("status changed: " + status);
    this.props.navigate(ROUTES.LEAVEREQUESTS);
  }

  async loadData(leaveRequestId) {
    // Get Leave Request
    const leaveRequest = await this.props.firebase
      .leaveRequest(leaveRequestId)
      .get()
      .then((doc) => {
        return { ...doc.data(), uid: doc.id };
      });

    const leaveRequestYear = leaveRequest.dateStart.toDate().getFullYear();

    // Get Leave Type
    const leaveType = await this.props.firebase
      .leaveType(leaveRequest.leaveTypeUid)
      .get()
      .then((doc) => {
        return { ...doc.data(), uid: doc.id };
      });

    // Get leaveRequest Days
    const requestDays = await this.props.firebase
      .leaveRequest(leaveRequest.uid)
      .collection('requestDays')
      .orderBy('day')
      .get()
      .then((querySnapshot) => {
        let days = [];
        querySnapshot.forEach(function (doc) {
          days.push({
            ...doc.data(),
            uid: doc.id,
          });
        });

        return days;
      });

    // Get user
    const user = await this.props.firebase
      .userFirestore(leaveRequest.userUid)
      .get()
      .then((doc) => {
        return { ...doc.data(), uid: doc.id };
      });

    // Get user´s leaveProfile
    const leaveProfile = await this.props.firebase.userGetLeaveProfile(
      user.uid
    );

    const userLeaveRequests = await this.props.firebase.leaveRequestsWithType(
      user.uid,
      leaveRequestYear
    );

    // calculate user stats
    const userStats = UserStats.load({
      year: leaveRequestYear,
      user,
      leaveProfile,
      leaveRequests: userLeaveRequests,
    });

    console.log(userStats.getStats());

    this.setState({
      leaveRequest,
      leaveType,
      requestDays,
      user,
      leaveRequestYear,
      userStats: userStats.getStats(),
    });
  }

  render() {
    const {
      leaveRequest,
      leaveType,
      requestDays,
      user,
      userStats,
    } = this.state;
    return (
      <div>
        <h3>Fraværsdetaljer</h3>
        {!leaveRequest && (
          <div className="fa-3x text-center">
            <i className="fas fa-spinner fa-spin"/>
          </div>
        )}
        <div className="row">
          <div className="col-md-8">
            {leaveRequest && (
              <>
                <RequestDetailsTable
                  leaveRequest={leaveRequest}
                  user={user}
                  leaveType={leaveType}
                />

                <div className="mb-3">
                  {leaveRequest.approvedStatus !== 'rejected' && (
                    <ApproveRejectButton
                      type="reject"
                      className="mr-3"
                      callback={this.onStatusChanged}
                      leaveRequestUid={leaveRequest.uid}
                    />
                  )}

                  {leaveRequest.approvedStatus !== 'approved' && (
                    <ApproveRejectButton
                      type="approve"
                      callback={this.onStatusChanged}
                      leaveRequestUid={leaveRequest.uid}
                    />
                  )}
                </div>
              </>
            )}
          </div>
          <div className="col-md-4">
            {leaveType && (
              <UserStatsBox
                userStats={userStats}
                leaveType={leaveType}
                leaveRequest={leaveRequest}
              />
            )}
          </div>
        </div>

        {requestDays && (
          <div className="row">
            <div className="col-md-6">
              <RequestDaysTable requestDays={requestDays} />
            </div>
          </div>
        )}

        <div>
          <LeaveRequestBackButton />
        </div>
      </div>
    );
  }
}
const RequestDetailsTable = (props) => (
  <table className="table table-sm">
    <tbody>
      <tr>
        <td>Navn:</td>
        <td>
          {props.user.displayName} &lt;{props.user.email}&gt;
        </td>
      </tr>
      <tr>
        <td>Type:</td>
        <td>
          {props.leaveType.icon && <i className={props.leaveType.icon}></i>}
          &nbsp;
          {props.leaveType.name}
        </td>
      </tr>

      <tr>
        <td>Krever godkjenning:</td>
        <td>
          {props.leaveType.requiresApproval ? (
            <strong>JA</strong>
          ) : (
            <strong>NEI</strong>
          )}
        </td>
      </tr>
      <tr>
        <td>Status:</td>
        <td>{props.leaveRequest.approvedStatus}</td>
      </tr>
      <tr>
        <td>Beskrivelse:</td>
        <td>{props.leaveRequest.description}</td>
      </tr>
      <tr>
        <td>Fra - til:</td>
        <td>
          {moment(props.leaveRequest.dateStart.toDate()).format('D. MMM YYYY')}-
          {moment(props.leaveRequest.dateEnd.toDate()).format('D. MMM YYYY')}
        </td>
      </tr>
      <tr>
        <td>Dager</td>
        <td>
          {props.leaveRequest.workDays} dager - {props.leaveRequest.totalHours}
          timer
        </td>
      </tr>
      <tr>
        <td></td>
        <td></td>
      </tr>
    </tbody>
  </table>
);
const RequestDaysTable = (props) => (
  <table className="table table-sm table-striped">
    <tbody>
      {props.requestDays.map((item) => (
        <tr
          key={item.uid}
          className={item.isWeekend ? 'table-danger' : undefined}
        >
          <td>{moment(item.day.toDate()).format('dddd')}</td>
          <td>{moment(item.day.toDate()).format('D. MMM YYYY')}</td>
          <td>{item.hours ? <span>{item.hours} timer</span> : ''}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default compose(withNavigate, withParams, withFirebase)(LeaveRequestShowPage);
