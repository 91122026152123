import React from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const SignOutButton = ({ firebase }) => (
  <Link
    to={ROUTES.LANDING}
    className="nav-link"
    onClick={() => firebase.doSignOut()}
  >
    <small title="Sign Out">
      <i className="fas fa-sign-out-alt"></i>
    </small>
  </Link>
);
export default withFirebase(SignOutButton);
