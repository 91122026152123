import React from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, isAdmin } from '../Session';
import LeaveRequestAdminTableView from "../LeaveRequest/LeaveRequestAdminTable";
import { useLocation } from "react-router-dom";

const LeaveRequestPage = () => {
  const location = useLocation();
  const filter = location?.state?.filter;
  return (
    <div>
      <h3>Fraværssøknader</h3>
      <hr />
      <LeaveRequestAdminTableView  filter={filter}/>
    </div>
  );
}

export default compose(
  withAuthorization(isAdmin),
  withFirebase
)(LeaveRequestPage);
