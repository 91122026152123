import React, { Component } from 'react';
import { compose } from 'recompose';

import { withAuthorization } from '../Session';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import withParams from "../App/withParams";
import withNavigate from "../App/withNavigate";

const EditLeaveTypePage = () => {
  return (
    <div>
      <h4>Edit Leave Type</h4>
      <hr />
      <EditLeaveTypeForm />
    </div>
  );
}

class EditLeaveTypeFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: null,
      leaveType: null,
      uid: null,
    };
  }

  componentDidMount() {
    const uid = this.props.params.uid;

    const ref = this.props.firebase.leaveType(uid);
    ref.get().then((doc) => {
      if (doc.exists) {
        const leaveType = doc.data();

        // console.log(todo);

        this.setState({
          key: doc.id,
          uid: uid,

          leaveType: leaveType,
          name: leaveType.name,
          description: leaveType.description,
          icon: leaveType.icon,
          requiresApproval: leaveType.requiresApproval,
          deductedLeave: leaveType.deductedLeave,
          deductedEgenmelding: leaveType.deductedEgenmelding || false,
          deductedOmsorgsdager: leaveType.deductedOmsorgsdager || false,
          isSickness: leaveType.isSickness,
          color: leaveType.color,
        });
      } else {
        console.log('No such document!');
      }
    });
  }

  onSubmit = (event) => {
    const {
      uid,
      name,
      description,
      icon,
      requiresApproval,
      deductedLeave,
      deductedEgenmelding,
      deductedOmsorgsdager,
      isSickness,
      color,
    } = this.state;

    this.props.firebase
      .leaveTypeUpdate(uid, {
        name,
        description,
        icon,
        requiresApproval,
        deductedLeave,
        deductedEgenmelding,
        deductedOmsorgsdager,
        isSickness,
        color,
      })
      .then(() => {
        //this.setState = { ...INITIAL_STATE };
        // console.log("doc updated");
        this.props.navigate(ROUTES.LEAVETYPES)
        //this.props.history.push(ROUTES.LEAVETYPES);
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onCheckboxChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  handleDateRangeChange = (dateRange) =>
    this.setState({
      dateRange: dateRange,
    });

  render() {
    const {
      leaveType,
      name,
      description,
      icon,
      requiresApproval,
      deductedLeave,
      deductedEgenmelding,
      deductedOmsorgsdager,
      isSickness,
      color,
    } = this.state;

    return (
      <>
        {leaveType && (
          <div>
            <form className="form" onSubmit={this.onSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  placeholder="Tittel..."
                  className="form-control mb-2 mr-sm-2 col-md-6"
                  value={name}
                  onChange={this.onChange}
                  required
                ></input>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="description"
                  placeholder="Beskrivelse..."
                  className="form-control mb-2 mr-sm-2 col-md-6"
                  value={description}
                  onChange={this.onChange}
                ></input>
              </div>
              <div className="form-group">
                <div style={{ display: 'flex' }}>
                  <input
                    type="text"
                    name="color"
                    placeholder="Fargekode HEX"
                    className="form-control mb-2 mr-sm-2 col-md-6"
                    value={color}
                    onChange={this.onChange}
                  ></input>
                  {color && (
                    <div
                      style={{
                        width: 30,
                        height: 30,
                        background: color,
                        borderRadius: 5,
                      }}
                    >
                      &nbsp;
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="icon"
                  placeholder="fas fa-icon-name"
                  className="form-control mb-2 mr-sm-2 col-md-6"
                  value={icon}
                  onChange={this.onChange}
                ></input>
                <small id="emailHelp" className="form-text text-muted">
                  Bruk CSS-klasser fra Font Awesome &nbsp;
                  <a
                    href="https://fontawesome.com/icons d=gallery"
                    rel="noopener noreferrer"
                    title="Gå til fontawesome.com/"
                    target="_blank"
                  >
                    <i className="fas fa-external-link-alt"></i>
                  </a>
                </small>
              </div>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  name="requiresApproval"
                  id="requiresApproval"
                  onChange={this.onCheckboxChange}
                  checked={requiresApproval}
                />
                <label className="form-check-label" htmlFor="requiresApproval">
                  Requires Approval
                </label>
              </div>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  name="deductedLeave"
                  id="deductedLeave"
                  onChange={this.onCheckboxChange}
                  checked={deductedLeave}
                />
                <label className="form-check-label" htmlFor="deductedLeave">
                  Deducted Leave
                </label>
              </div>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  name="deductedEgenmelding"
                  id="deductedEgenmelding"
                  onChange={this.onCheckboxChange}
                  checked={deductedEgenmelding}
                />
                <label
                  className="form-check-label"
                  htmlFor="deductedEgenmelding"
                >
                  Deducted Egenmelding
                </label>
              </div>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  name="deductedOmsorgsdager"
                  id="deductedOmsorgsdager"
                  onChange={this.onCheckboxChange}
                  checked={deductedOmsorgsdager}
                />
                <label
                  className="form-check-label"
                  htmlFor="deductedOmsorgsdager"
                >
                  Deducted Omsorgsdager
                </label>
              </div>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  name="isSickness"
                  id="isSickness"
                  onChange={this.onCheckboxChange}
                  checked={isSickness}
                />
                <label className="form-check-label" htmlFor="isSickness">
                  Is Sickness?
                </label>
              </div>

              <div className="form-group">
                <button type="submit" className="btn btn-primary  mb-2">
                  OPPDATER <i className="far fa-save"></i>
                </button>
              </div>
            </form>
          </div>
        )}
      </>
    );
  }
}

const EditLeaveTypeForm = compose(
  withNavigate,
  withParams,
  withFirebase
)(EditLeaveTypeFormBase);
const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(EditLeaveTypePage);
