import React, { Component } from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';

import { withAuthorization, isAdmin } from '../Session';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import withParams from "../App/withParams";

const EditUserPage = () => (
  <div className="row">
    <div className="col-md-12">
      <h4>Rediger Bruker</h4>
      <hr />
      <EditUserForm />

      <Link to={ROUTES.ADMIN} className="btn btn-sm btn-warning">
        <i className="fas fa-backward" /> Tilbake
      </Link>
    </div>
  </div>
);

class UserExtraDaysFormBase extends Component {
  constructor(props) {
    super(props);

    const currentYear = new Date().getFullYear();

    let extraDays = this.props.user.extraDays || {};

    // set default values for +/- 1 year
    if (!extraDays[currentYear - 1]) {
      extraDays[currentYear - 1] = 0;
    }
    if (!extraDays[currentYear]) {
      extraDays[currentYear] = 0;
    }
    if (!extraDays[currentYear + 1]) {
      extraDays[currentYear + 1] = 0;
    }

    this.state = {
      extraDays: extraDays,
    };
  }

  onChange = (event) => {
    const { extraDays } = this.state;

    extraDays[event.target.name] = parseInt(event.target.value);
    this.setState({ extraDays });
  };

  saveExtraDays = () => {
    this.props.firebase.firestore
      .collection('users')
      .doc(this.props.user.uid)
      .set(
        {
          extraDays: this.state.extraDays,
        },
        { merge: true }
      );
  };

  render() {
    const { extraDays } = this.state;
    return (
      <>
        {extraDays && (
          <>
            {Object.keys(extraDays).map((year) => (
              <div key={year} className="mb-1">
                <span className="d-inline-block" style={{ width: 60 + 'px' }}>
                  {year}:
                </span>
                <input
                  className="formControl col-sm-4"
                  type="number"
                  step="1"
                  min="0"
                  required
                  name={year}
                  value={extraDays[year]}
                  onChange={this.onChange}
                />
              </div>
            ))}

            <button
              className="btn btn-sm btn-primary mt-2"
              onClick={this.saveExtraDays}
            >
              Lagre
            </button>
          </>
        )}
      </>
    );
  }
}
class EditUserFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
    };
  }

  componentDidMount() {
    const uid = this.props.params.uid;

    this.props.firebase
      .userFirestore(uid)
      .get()
      .then((snapshot) => {
        this.setState({
          user: { uid: snapshot.id, ...snapshot.data() },
        });
      });
  }

  deactivateUser = (user) => {
    this.changeUserActivity(user, true);
  };

  activateUser = (user) => {
    this.changeUserActivity(user, null);
  }

  changeUserActivity = (user, activity) => {
    this.props.firebase.userUpdate(user.uid, {
      deactivated: activity
    });
    user["deactivated"] = activity;
    this.setState({
      user: user
    });
  }

  render() {
    const { user } = this.state;
    return (
      <div>
        {user && (
          <>
            <div className="form-group row">
              {user.deactivated && (
                <>
                  <button
                    style={{marginLeft: 15 + "px", color: "white", backgroundColor: "green"}}
                    onClick={() => this.activateUser(user)}
                  >
                    Aktiver bruker
                  </button>
                </>
              )}
              {user.deactivated == null && (
                <>
                  <button
                    style={{marginLeft: 15 + "px", color: "white", backgroundColor: "#aa0000"}}
                    onClick={() => this.deactivateUser(user)}
                  >
                    Deaktiver bruker
                  </button>
                </>
              )}
            </div>
            <div className="form-group row">
              <label
                htmlFor="inputName"
                className="col-sm-2 col-form-label text-right"
              >
                Navn:
              </label>
              <div className="col-sm-6">
                <input
                  type="text"
                  name="name"
                  id="inputName"
                  disabled
                  className="form-control"
                  value={user.displayName}
                />
              </div>
            </div>

            <div className="form-group row">
              <label
                htmlFor="inputEmail"
                className="col-sm-2 col-form-label text-right"
              >
                Epost:
              </label>
              <div className="col-sm-6">
                <input
                  type="text"
                  name="email"
                  id="inputEmail"
                  disabled
                  className="form-control"
                  value={user.email}
                />
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="inputName"
                className="col-sm-2 col-form-label text-right"
              >
                Ekstra feriedager:
              </label>
              <div className="col-sm-6">
                <UserExtraDaysForm user={user} />
              </div>
            </div>
          </>
        )}
        {!user && (
          <div className="fa-3x text-center">
            <i className="fas fa-spinner fa-spin" />
          </div>
        )}
      </div>
    );
  }
}

const UserExtraDaysForm = compose(withFirebase)(UserExtraDaysFormBase);
const EditUserForm = compose(withParams, withFirebase)(EditUserFormBase);

export default withAuthorization(isAdmin)(EditUserPage);
