import React from 'react';
import PropTypes from 'prop-types';
import Timeline, { DateHeader, SidebarHeader, TimelineHeaders, } from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import moment from 'moment';
import './CalendarTimeline.css';
import { Link } from "react-router-dom";
import ROUTES from "../../constants/routes";
import { isAdmin } from "../Session";

const LeaveRequestSummary = ({ leaveRequest }) => {
  const from = leaveRequest.dateStartMoment.format('dddd Do MMMM');
  const to = leaveRequest.dateEndMoment.format('dddd Do MMMM');

  return (
    <div className="lr-summary">
      <div className="lr-summary-heading">
        <h3>{leaveRequest.leaveType.name}</h3>
      </div>
      <div className="lr-summary-body">
        <div>
          <p>
            <strong>Tidsrom:</strong> {` ${from} - ${to}`}
          </p>
          <p>
            <strong>Innmelder:</strong>{' '}
            {` ${leaveRequest.userName || leaveRequest.userEmail}`}
          </p>
          {leaveRequest?.approvedByName && (<p>
            <strong>Godkjent av:</strong> {` ${leaveRequest.approvedByName}`}
          </p>)}
          <p>
            <strong>Kalenderdager:</strong> {` ${leaveRequest.totalDays}`}
          </p>
          <p>
            <strong>Arbeidsdager:</strong> {` ${leaveRequest.workDays}`}
          </p>
          <p>
            <strong>Timer:</strong> {` ${leaveRequest.totalHours}`}
          </p>
        </div>
      </div>
    </div>
  );
};

const absenceRenderer = ({ item, itemContext, getItemProps }) => {
  return (
    <div {...getItemProps({
        style: {
          background: item.bgColor,
          borderRadius: '2px',
        }})}>
      <div style={{ visibility: "hidden" }}>
        {itemContext.title}
      </div>
    </div>
  )};


const getGroupRenderer = ( authUser ) => {
  return ({ group }) => (
    <>
      <div>
        {isAdmin(authUser) ? (
          <Link to={ROUTES.LEAVEREQUESTS}
            state={{ filter: group.title }}
          >
            {group.title}
          </Link>
        ) : (
          <div>
            {group.title}
          </div>
        )
        }
      </div>
    </>
  );
}

const CalendarTimeline = ({
  groups,
  items,
  headerColor,
  leaveRequests,
  enableSummary,
  authUser
}) => {
  const [showSummary, setShowSummary] = React.useState(false);
  const [selectedLeaveRequest, setSelectedLeaveRequest] = React.useState('');


  const groupRenderer = getGroupRenderer(authUser);

  return (
    <div>
      <Timeline
        groups={groups}
        items={items}
        defaultTimeStart={moment().add(-3, 'month').startOf('month')}
        defaultTimeEnd={moment().add(9, 'month').endOf('month')}
        stackItems
        itemRenderer={absenceRenderer}
        groupRenderer={groupRenderer}
        canMove={false}
        canResize={false}
        onItemSelect={(itemId) => {
          const timelineItem = items.find((i) => i.id === itemId);
          const leaveRequest = leaveRequests.find(
            (lr) => lr.uid === timelineItem.leaveRequestId
          );
          setShowSummary(true);
          setSelectedLeaveRequest(leaveRequest);
        }}
        onItemDeselect={(itemId) => {
          setShowSummary(false);
          setSelectedLeaveRequest('');
        }}
      >
        <TimelineHeaders>
          <SidebarHeader style={{ background: 'green' }}>
            {({ getRootProps }) => {
              const props = getRootProps();
              const styles = {
                ...props.style,
                background: headerColor,
              };
              return <div style={{ ...styles }}>&nbsp;</div>;
            }}
          </SidebarHeader>
          <DateHeader
            unit="primaryHeader"
            style={{ background: headerColor, color: 'black' }}
          />
          <DateHeader />
        </TimelineHeaders>
      </Timeline>
      {enableSummary && showSummary && selectedLeaveRequest && (
        <LeaveRequestSummary leaveRequest={selectedLeaveRequest} />
      )}
    </div>
  );
};

CalendarTimeline.propTypes = {
  headerColor: PropTypes.string,
  groups: PropTypes.array,
  items: PropTypes.array,
  enableSummary: PropTypes.bool,
};

CalendarTimeline.defaultProps = {
  headerColor: '#63a873',
  groups: [],
  items: [],
  enableSummary: false,
};

export default CalendarTimeline;
