import React from 'react';
import { compose } from 'recompose';
import { withAuthorization, isAdmin } from '../Session';
import { withFirebase } from '../Firebase';
import AddHoliday from './AddHoliday';
import BankHolidayList from './BankHolidayList';

const BankHolidaysPage = () => (
  <div>
    <h3>Offentlige helligdager</h3>
    <hr className="mb-5" />
    <BankHolidayList />
    <AddHoliday />
  </div>
);

export default compose(
  withAuthorization(isAdmin),
  withFirebase
)(BankHolidaysPage);
