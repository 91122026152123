import React from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, isAdmin } from '../Session';
import AddLeaveForm from './AddLeaveTypeForm';
import LeaveTypeList from './LeaveTypeList';

const LeaveTypesPage = () => (
  <div>
    <h3>Fraværstyper</h3>

    <LeaveTypeList />
    <hr className="mb-5" />

    <AddLeaveForm />
  </div>
);

export default compose(
  withAuthorization(isAdmin),
  withFirebase
)(LeaveTypesPage);
