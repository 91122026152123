class FirestoreDepartmentApi {
  constructor(firestore, fieldValue) {
    this.firestore = firestore;
    // https://firebase.google.com/docs/reference/js/firebase.firestore.FieldValue#servertimestamp
    this.fieldValue = fieldValue;
  }

  getDepartmentsRefs() {
    return this.firestore.collection('departments');
  }

  getDepartmentRef(departmentId) {
    return this.firestore.doc(`departments/${departmentId}`);
  }

  createDepartment(data, auth) {
    return this.firestore.collection('departments').add({
      ...data,
      createdUserId: auth.currentUser.uid,
      createdEmail: auth.currentUser.email,
      created: this.fieldValue.serverTimestamp(),
      updated: null,
    });
  }
}

export default FirestoreDepartmentApi;
