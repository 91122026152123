import React, { useEffect } from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { useNavigate } from "react-router-dom";

const withAuthorization = (condition) => (Component) => {
  const WithAuthorization = ( { firebase, ...props } ) => {
    const navigate = useNavigate();

    useEffect( () => {
      const authListener = firebase.onAuthUserListener( authUser => {
        if ( !condition( authUser ) ) {
          navigate(ROUTES.SIGN_IN, { replace: true } )
        }
      }, () => navigate( ROUTES.SIGN_IN, { replace: true } ) )

      return authListener;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] )

    return (
      <AuthUserContext.Consumer>
        {( authUser ) =>
          condition( authUser ) ? <Component {...props} /> : null
        }
      </AuthUserContext.Consumer>
    );
  }

  return withFirebase(WithAuthorization);
};

export default withAuthorization;
