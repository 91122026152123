import React, { Component } from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';

class LeaveProfileList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      leaveProfiles: [],
      loading: false,
    };
  }

  onDelete = (uid, event) => {
    this.props.firebase
      .leaveProfileDelete(uid)
      .then(function () {
        console.log('Leave Type successfully deleted!');
      })
      .catch(function (error) {
        console.error('Error removing Leave Type : ', error);
      });
  };

  onSetDefault(uid) {
    this.props.firebase
      .leaveProfileSetDefault(uid)
      .then(function () {
        console.log('Leave Profile - default set');
      })
      .catch(function (error) {
        console.error('Error: ', error);
      });
  }

  componentDidMount() {
    this.setState({ loading: true });

    let collectionRef = this.props.firebase.leaveProfiles();

    this.unsubscribe = collectionRef.onSnapshot((snapshot) => {
      let leaveProfiles = [];

      snapshot.forEach((doc) =>
        leaveProfiles.push({ ...doc.data(), uid: doc.id })
      );

      this.setState({
        leaveProfiles,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { leaveProfiles } = this.state;
    return (
      <div>
        <table className="table">
          <thead>
            <tr>
              <th>Navn </th>
              <th className="text-center">
                Dager
                <br />
                Ferie
              </th>
              <th className="text-center">
                Dager
                <br />
                Egenmelding
              </th>
              <th className="text-center">
                Dager
                <br />
                Omsorg
              </th>
              <th className="text-center">
                Timer
                <br />
                Per Dag
              </th>
              <th className="text-center">Default</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {leaveProfiles.map((leaveProfile) => (
              <tr
                key={leaveProfile.uid}
                className={(
                  leaveProfile.isDefault && 'table-success'
                ).toString()}
              >
                <td>
                  <strong>{leaveProfile.name}</strong>
                </td>
                <td className="text-center align-middle">
                  {leaveProfile.ferieDager}
                </td>
                <td className="text-center align-middle">
                  {leaveProfile.egenmeldingsDager}
                </td>
                <td className="text-center align-middle">
                  {leaveProfile.omsorgsDager}
                </td>

                <td className="text-center align-middle">
                  {leaveProfile.hoursPerDay}
                </td>
                <td className="text-center align-middle">
                  {leaveProfile.isDefault && <div>JA</div>}
                  {!leaveProfile.isDefault && (
                    <button
                      className="btn btn-success w-100"
                      onClick={(e) => this.onSetDefault(leaveProfile.uid)}
                    >
                      <i className="fas fa-check"></i> Set Default
                    </button>
                  )}
                </td>
                <td>
                  <Link
                    to={`/leaveprofiles/edit/${leaveProfile.uid}`}
                    className="btn btn-sm btn-primary mr-2"
                  >
                    <i className="far fa-edit"></i>
                  </Link>
                  {/* 
                {!leaveProfile.isDefault && 
                  <button className="btn btn-sm btn-danger" onClick={(e) => this.onDelete(leaveProfile.uid, e)}><i className="far fa-trash-alt"></i></button>
                }
                */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

const condition = (authUser) => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase
)(LeaveProfileList);
