import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { AuthUserContext, isAdmin } from '../Session';
import SignOutButton from './SignOutButton';
import ROUTES from '../../constants/routes';
import { APP_NAME } from '../../constants/appInfo';

const Navigation = ({ hideOnRoutes }) => {
  const location = useLocation();
  if (hideOnRoutes && hideOnRoutes.find((r) => r === location.pathname)) {
    return null;
  }
  return (
    <>
      <nav>
        <div className="autocollapsegrid main-nav">
          <AuthUserContext.Consumer>
            {(authUser) =>
              authUser ? (
                <NavigationAuth authUser={authUser} location={location} />
              ) : (
                <NavigationNonAuth />
              )
            }
          </AuthUserContext.Consumer>
        </div>
      </nav>
      <hr />
    </>
  );
};

const NavigationAuth = ({ authUser, location }) => {
  return (
    <>
      <span className="gustav">
        <Link to={ROUTES.HOME}>
          <i className="fas fa-leaf" /> {APP_NAME}
        </Link>
      </span>
      <NavItem
        text={'Min side'}
        route={ROUTES.ACCOUNT}
        currentPath={location.pathname}
      />
      {isAdmin(authUser) && (
        <>
          <NavItem
            text={'Brukere'}
            route={ROUTES.ADMIN}
            currentPath={location.pathname}
          />
          <NavItem
            text={'Fraværstyper'}
            route={ROUTES.LEAVETYPES}
            currentPath={location.pathname}
          />
          <NavItem
            text={'Fraværsprofiler'}
            route={ROUTES.LEAVEPROFILES}
            currentPath={location.pathname}
          />
          <NavItem
            text={'Fraværssøknader'}
            route={ROUTES.LEAVEREQUESTS}
            currentPath={location.pathname}
          />
          <NavItem
            text={'Avdelinger'}
            route={ROUTES.DEPARTMENT}
            currentPath={location.pathname}
          />
          <NavItem
            text={'Helligdager'}
            route={ROUTES.BANKHOLIDAYS}
            currentPath={location.pathname}
          />
          <NavItem
            text={'Rapporter'}
            route={ROUTES.REPORTS}
            currentPath={location.pathname}
          />
        </>
      )}
      <SignOutButton />
    </>
  );
};

const NavigationNonAuth = () => {
  return (
    <>
      <span className="gustav">
        <Link to={ROUTES.HOME}>
          <i className="fas fa-leaf" /> {APP_NAME}
        </Link>
      </span>
      <Link to={ROUTES.SIGN_IN} className="nav-link">
        Sign In
      </Link>
    </>
  );
};

const NavItem = ({ text, route, currentPath }) => {
  return (
    <span className={currentPath === route ? 'active' : ''}>
      <Link to={route}>{text}</Link>
    </span>
  );
};

export default Navigation;
