import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/functions';
import base64 from 'base-64';
import FirestoreUserApi from './FirestoreUserApi';
import FirestoreLeaveRequestApi from './FirestoreLeaveRequestApi';
import FirestoreLeaveProfileApi from './FirestoreLeaveProfileApi';
import FirestoreLeaveTypeApi from './FirestoreLeaveTypeApi';
import FirestoreDepartmentApi from './FirestoreDepartmentApi';
import FirestoreHolidayApi from './FirestoreHolidayApi';
import StatsService from './StatisticsService';
import AuthService from './AuthService';

class Firebase {
  constructor() {
    const config = this.getFirebaseConfig();
    app.initializeApp(config);
    this.auth = app.auth();
    this.firestore = app.firestore();
    this.fieldValue = app.firestore.FieldValue;

    this.userApi = new FirestoreUserApi(this.firestore, this.fieldValue);
    this.leaveProfileApi = new FirestoreLeaveProfileApi(
      this.firestore,
      this.fieldValue,
      this.userApi
    );
    this.leaveTypeApi = new FirestoreLeaveTypeApi(
      this.firestore,
      this.fieldValue
    );
    this.leaveRequestApi = new FirestoreLeaveRequestApi(
      this.firestore,
      this.fieldValue,
      this.leaveTypeApi,
      this.userApi
    );
    this.departmentApi = new FirestoreDepartmentApi(
      this.firestore,
      this.fieldValue
    );
    this.holidayApi = new FirestoreHolidayApi(this.firestore, this.fieldValue);
    this.statsService = new StatsService(
      this.leaveProfileApi,
      this.leaveTypeApi,
      this.leaveRequestApi
    );
    this.authService = new AuthService(app, this.userApi);
  }

  getFirebaseConfig() {
    if (!process.env.REACT_APP_FIREBASE_SETTINGS) {
      throw new Error('process.env.REACT_APP_FIREBASE_SETTINGS not set');
    }

    const settings = JSON.parse(
      base64.decode(process.env.REACT_APP_FIREBASE_SETTINGS)
    );

    return {
      apiKey: settings.apiKey,
      authDomain: settings.authDomain,
      databaseURL: settings.databaseURL,
      projectId: settings.projectId,
      storageBucket: settings.storageBucket,
      messagingSenderId: settings.messagingSenderId,
    };
  }

  //
  // AUTHENTICATION
  //

  doSignInWithGoogle() {
    return this.authService.signInWithGoogle();
  }

  doSignOut() {
    return this.authService.signOut();
  }

  onAuthUserListener(next, fallback) {
    return this.authService.onUserAuthenticated(next, fallback);
  }

  //
  // USER
  //

  userFirestore(userId) {
    return this.userApi.getUserRef(userId);
  }

  usersFirestore() {
    return this.userApi.getUsersRefs();
  }

  user(userId) {
    return this.userApi.getUser(userId);
  }

  userAdmin(userId) {
    return this.userApi.assignAdminRole(userId);
  }

  userAdminEmail(userId) {
    return this.userApi.assignAdminEmailRole(userId);
  }

  userRemoveAdmin(userId) {
    return this.userApi.removeAdminRole(userId);
  }

  userRemoveAdminEmail(userId) {
    return this.userApi.removeAdminEmailRole(userId);
  }

  userUpdate(userId, data) {
    return this.userApi.updateUser(userId, data);
  }

  createUser(socialAuthUser) {
    return this.leaveProfileApi
      .getDefaultLeaveProfile()
      .then((defaultLeaveProfile) => {
        return this.userApi.createUser(socialAuthUser, defaultLeaveProfile);
      });
  }

  //
  // STATISTICS
  //

  userCalculateYearStats(uid, year) {
    return this.statsService.calculateYearStats(uid, year);
  }

  //
  // LEAVE REQUEST
  //

  leaveRequest(uid) {
    return this.leaveRequestApi.getLeaveRequestRef(uid);
  }

  leaveRequests() {
    return this.leaveRequestApi.getLeaveRequestsRefs();
  }

  leaveRequestAdd = function (data, requestDays) {
    return this.leaveRequestApi.createLeaveRequest(
      data,
      requestDays,
      this.auth
    );
  };

  leaveRequestDelete(uid) {
    return this.leaveRequestApi.deleteLeaveRequest(uid);
  }

  leaveRequestDeactivate(uid) {
    return this.leaveRequestApi.deactivateLeaveRequest(uid);
  }

  leaveRequestActivate(uid) {
    return this.leaveRequestApi.activateLeaveRequest(uid);
  }

  leaveRequestApprove(uid) {
    return this.leaveRequestApi.approveLeaveRequest(uid, this.auth);
  }

  leaveRequestReject(uid) {
    return this.leaveRequestApi.rejectLeaveRequest(uid, this.auth);
  }

  leaveRequestsWithType(userId, year) {
    return this.leaveRequestApi.getLeaveRequestsInYearForUser(userId, year);
  }

  allUsersIncludingApprovedLeaveRequests( year){
    return this.leaveRequestApi.getAllUsersIncludingApprovedLeaveRequestsInYear(year);
  }

  approvedLeaveRequestsForAllUsers(year) {
    return this.leaveRequestApi.getLeaveRequestsInYearForAllUsers(year);
  }

  //
  // LEAVE PROFILE
  //

  leaveProfile(uid) {
    return this.leaveProfileApi.getLeaveProfileRef(uid);
  }

  leaveProfiles() {
    return this.leaveProfileApi.getLeaveProfilesRefs();
  }

  leaveProfilesCollection() {
    return this.leaveProfileApi.getLeaveProfiles();
  }

  leaveProfileAdd(data) {
    return this.leaveProfileApi.createLeaveProfile(data);
  }

  leaveProfileUpdate(uid, data) {
    return this.leaveProfileApi.updateLeaveProfile(uid, data);
  }

  leaveProfileDelete(uid) {
    return this.leaveProfileApi.deleteLeaveProfile(uid);
  }

  leaveProfileGetDefault() {
    return this.leaveProfileApi.getDefaultLeaveProfile();
  }

  leaveProfileSetDefault(uid) {
    return this.leaveProfileApi.setLeaveProfileAsDefault(uid);
  }

  userGetLeaveProfile(uid) {
    return this.leaveProfileApi.getLeaveProfileOrDefaultForUser(uid);
  }

  //
  // LEAVE TYPE
  //

  leaveType(uid) {
    return this.leaveTypeApi.getLeaveTypeRef(uid);
  }

  leaveTypes() {
    return this.leaveTypeApi.getLeaveTypesRefs();
  }

  leaveTypeAdd(data) {
    return this.leaveTypeApi.createLeaveType(data, this.auth);
  }

  leaveTypeUpdate(uid, data) {
    return this.leaveTypeApi.updateLeaveType(uid, data);
  }

  leaveTypeDelete(uid) {
    return this.leaveTypeApi.deleteLeaveType(uid);
  }

  leaveTypeCollection() {
    return this.leaveTypeApi.getLeaveTypes();
  }

  //
  // DEPARTMENT
  //

  department(uid) {
    return this.departmentApi.getDepartmentRef(uid);
  }

  departments() {
    return this.departmentApi.getDepartmentsRefs();
  }

  departmentAdd(data) {
    return this.departmentApi.createDepartment(data, this.auth);
  }

  //
  // HOLIDAY
  //

  bankHoliday(uid) {
    return this.holidayApi.getHolidayRef(uid);
  }

  bankHolidays() {
    return this.holidayApi.getHolidaysRefs();
  }

  holidayAddApi(holiday) {
    return this.holidayApi.createHolidayFromApi(holiday, this.auth);
  }

  holidayAdd(data) {
    return this.holidayApi.createHolidayFromInput(data);
  }

  holidayDeactivate(uid) {
    return this.holidayApi.deactivateHoliday(uid);
  }

  holidayActivate(uid) {
    return this.holidayApi.activateHoliday(uid);
  }

  bankHolidaysCollection() {
    return this.holidayApi.getActiveHolidays();
  }

  bankHolidayDelete(uid) {
    return this.holidayApi.deleteHoliday(uid);
  }
}

export default Firebase;
