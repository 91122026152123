import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { withFirebase } from '../Firebase';
import {
  AuthUserContext,
  withAuthorization,
  withAuthentication,
} from '../Session';
import * as ROUTES from '../../constants/routes';

import CalendarTimeline, { MODE_SINGLE_USER } from '../CalendarTimeline';
import LeaveRequestList from '../LeaveRequest/LeaveRequestList';
import Spinner from '../Spinner';

// https://firebase.google.com/docs/auth/web/manage-users
class UserDaysStatsBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stats: null,
      userLeaveProfile: null,
      feriedagerPercent: 0,
      user: null,
    };
  }

  componentDidMount() {
    this.props.firebase
      .userCalculateYearStats(this.props.authUser.uid)
      .then((stats) => {
        this.setState({ stats: stats });
      });

    this.props.firebase
      .userFirestore(this.props.authUser.uid)
      .get()
      .then((doc) => {
        this.setState({ user: { ...doc.data(), uid: doc.id } });
      });
  }

  render() {
    const { user, stats, userLeaveProfile, feriedagerPercent } = this.state;
    return (
      <>
        {stats && (
          <>
            <div className="row mb-3">
              <div className="col" style={{ maxWidth: 8 + 'em' }}>
                <img src={user.photoURL} alt="Profile" width="85" />
                <a
                  href={'https://myaccount.google.com/u/' + user.email}
                  className="small center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google-konto
                </a>
              </div>
              <div className="col">
                <h2>
                  {stats.year}: {user.displayName}{' '}
                </h2>
                {user && user.extraDays && (
                  <>
                    {Object.keys(user.extraDays).map((year) => (
                      <div key={year}>
                        {user.extraDays[year] > 0 && (
                          <span>
                            Ekstra feriedager {year}: {user.extraDays[year]}{' '}
                            dager
                          </span>
                        )}
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
            <div className="center">
              <div className="row text-center">
                <div className="col" style={{ width: 33 + '%' }}>
                  <h4>Ferie</h4>
                  <p>
                    {stats.ferieDagerBrukt} av {stats.ferieDagerTilgjengelig}{' '}
                    dager
                  </p>
                  <div className="mx-auto" style={{ maxWidth: 10 + 'em' }}>
                    <CircularProgressbar
                      value={stats.ferieTimerBruktProsent}
                      text={`${parseInt(stats.ferieTimerBruktProsent)}%`}
                    />
                  </div>
                </div>
                <div className="col" style={{ width: 33 + '%' }}>
                  <h4>Egenmelding</h4>
                  <p>
                    {stats.egenmeldingsDagerBrukt} av{' '}
                    {stats.egenmeldingsDagerTilgjengelig} dager
                  </p>
                  <div className="mx-auto" style={{ maxWidth: 10 + 'em' }}>
                    <CircularProgressbar
                      value={stats.egenmeldingsTimerBruktProsent}
                      text={`${parseInt(stats.egenmeldingsTimerBruktProsent)}%`}
                    />
                  </div>
                </div>
                <div className="col" style={{ width: 32 + '%' }}>
                  <h4>Omsorgsdager</h4>
                  <p>
                    {stats.omsorgsDagerBrukt} av{' '}
                    {stats.omsorgsDagerTilgjengelig} dager
                  </p>
                  <div className="mx-auto" style={{ maxWidth: 10 + 'em' }}>
                    <CircularProgressbar
                      value={stats.omsorgsTimerBruktProsent}
                      text={`${parseInt(stats.omsorgsTimerBruktProsent)}%`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <Spinner enabled={!stats} color="lightgray" />
        {userLeaveProfile && (
          <h3>
            Available Days: {userLeaveProfile.ferieDager} - {feriedagerPercent}%
            used
          </h3>
        )}
      </>
    );
  }
}

class AccountPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  render() {
    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <>
            <UserDaysStats authUser={authUser} />
            <div className="row">
              <div className="col-md-12 pt-5">
                <CalendarTimeline user={authUser} mode={MODE_SINGLE_USER} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 pt-5">
                <Link
                  to={ROUTES.LEAVEREQUESTS_NEW}
                  className="btn btn-success mb-4"
                >
                  <i className="fas fa-plus-circle" /> Registrer nytt fravær
                </Link>

                <LeaveRequestList uid={authUser.uid} />
              </div>
            </div>
          </>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = (authUser) => !!authUser;
const UserDaysStats = compose(
  withAuthentication,
  withFirebase
)(UserDaysStatsBase);

export default compose(
  withAuthorization(condition),
  withAuthentication,
  withFirebase
)(AccountPage);
