class FirestoreLeaveTypeApi {
  constructor(firestore, fieldValue) {
    this.firestore = firestore;
    // https://firebase.google.com/docs/reference/js/firebase.firestore.FieldValue#servertimestamp
    this.fieldValue = fieldValue;
  }

  getLeaveTypesRefs() {
    return this.firestore.collection('leaveTypes');
  }

  getLeaveTypeRef(leaveTypeId) {
    return this.firestore.doc(`leaveTypes/${leaveTypeId}`);
  }

  getLeaveTypes() {
    var docs = {};

    return this.getLeaveTypesRefs()
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          //docs.push({ ...doc.data(), uid: doc.id });
          docs[doc.id] = { ...doc.data(), uid: doc.id };
        });

        return docs;
      });
  }

  createLeaveType(data, auth) {
    return this.firestore.collection('leaveTypes').add({
      ...data,
      createdUserId: auth.currentUser.uid,
      createdEmail: auth.currentUser.email,
      created: this.fieldValue.serverTimestamp(),
      updated: null,
    });
  }

  updateLeaveType(leaveTypeId, data) {
    return this.firestore
      .collection('leaveTypes')
      .doc(leaveTypeId)
      .set(
        {
          ...data,
          updated: this.fieldValue.serverTimestamp(),
        },
        { merge: true }
      )
      .then(function () {
        console.log('leaveTypeUpdate successful');
      })
      .catch(function (error) {
        console.error('leaveTypeUpdate Error: ', error);
      });
  }

  deleteLeaveType(leaveTypeId) {
    return this.firestore.collection('leaveTypes').doc(leaveTypeId).delete();
  }
}

export default FirestoreLeaveTypeApi;
