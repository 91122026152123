function isApparatUser(user) {
  if (!user.emailVerified) {
    return false;
  }

  if (!user.email.endsWith('@apparat.no')) {
    return false;
  }

  return true;
}

class AuthService {
  constructor(app, userApi) {
    this.app = app;
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.userApi = userApi;
  }

  auth() {
    return this.app.auth();
  }

  signInWithGoogle() {
    return this.auth().signInWithPopup(this.googleProvider);
  }

  signOut() {
    return this.auth().signOut();
  }

  onUserAuthenticated(next, fallback) {
    return this.auth().onAuthStateChanged((authUser) => {
      if (authUser && isApparatUser(authUser)) {
        this.userApi
          .getUserRef(authUser.uid)
          .get()
          .then((snapshot) => {
            const dbUser = snapshot.data();

            if (dbUser && !dbUser.roles) {
              dbUser.roles = {};
            }

            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              photoURL: authUser.photoURL,
              displayName: authUser.displayName,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });
  }
}

export default AuthService;
