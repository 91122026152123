class StatisticsService {
  constructor(leaveProfileApi, leaveTypeApi, leaveRequestApi) {
    this.leaveProfileApi = leaveProfileApi;
    this.leaveTypeApi = leaveTypeApi;
    this.leaveRequestApi = leaveRequestApi;
  }

  calculateYearStats(uid, year) {
    if (!year) {
      year = new Date().getFullYear();
    }

    let stats = {
      ferieDagerBrukt: 0,
      //ferieDagerBruktProsent: 0,
      ferieDagerTilgjengelig: 0,

      ferieTimerBrukt: 0,
      ferieTimerBruktProsent: 0,
      ferieTimerTilgjengelig: 0,

      egenmeldingsDagerBrukt: 0,
      //egenmeldingsDagerBruktProsent: 0,
      egenmeldingsDagerTilgjengelig: 0,

      egenmeldingsTimerBrukt: 0,
      egenmeldingsTimerBruktProsent: 0,
      egenmeldingsTimerTilgjengelig: 0,

      omsorgsDagerBrukt: 0,
      //omsorgsDagerBruktProsent: 0,
      omsorgsDagerTilgjengelig: 0,

      omsorgsTimerBrukt: 0,
      omsorgsTimerBruktProsent: 0,
      omsorgsTimerTilgjengelig: 0,

      year: year,
    };

    // get user's leaveProfile
    return this.leaveProfileApi
      .getLeaveProfileOrDefaultForUser(uid)
      .then((userLeaveProfile) => {
        if (userLeaveProfile) {
          stats.ferieDagerTilgjengelig = userLeaveProfile.ferieDager;
          stats.egenmeldingsDagerTilgjengelig =
            userLeaveProfile.egenmeldingsDager;
          stats.omsorgsDagerTilgjengelig = userLeaveProfile.omsorgsDager;

          stats.ferieTimerTilgjengelig =
            userLeaveProfile.ferieDager * userLeaveProfile.hoursPerDay;
          stats.egenmeldingsTimerTilgjengelig =
            userLeaveProfile.egenmeldingsDager * userLeaveProfile.hoursPerDay;
          stats.omsorgsTimerTilgjengelig =
            userLeaveProfile.omsorgsDager * userLeaveProfile.hoursPerDay;
        }

        // get leaveTypes
        return this.leaveTypeApi.getLeaveTypes().then((leaveTypes) => {
          // get user's leaveRequests for Year
          return this.leaveRequestApi
            .getLeaveRequestsRefs()
            .where('approvedStatus', '==', 'approved')
            .where('status', '==', 'active')
            .where('userUid', '==', uid)
            .where('dateStart', '>=', new Date(year, 0, 1))
            .where('dateStart', '<', new Date(year, 11, 31))
            .get()
            .then((querySnapshot) => {
              // loop leaveRequests
              querySnapshot.forEach(function (doc) {
                let leaveRequest = doc.data();
                // join leaveReques with leaveTypes
                let leaveType = leaveTypes[doc.get('leaveTypeUid')];

                //  calculate used days
                if (leaveType.deductedLeave) {
                  stats.ferieDagerBrukt += leaveRequest.workDays;
                }

                if (leaveType.deductedEgenmelding) {
                  stats.egenmeldingsDagerBrukt += leaveRequest.workDays;
                }

                if (leaveType.deductedOmsorgsdager) {
                  stats.omsorgsDagerBrukt += leaveRequest.workDays;
                }
              });

              if (userLeaveProfile) {
                //  calculate used/available hours
                stats.ferieTimerBrukt =
                  stats.ferieDagerBrukt * userLeaveProfile.hoursPerDay;
                stats.egenmeldingsTimerBrukt =
                  stats.egenmeldingsDagerBrukt * userLeaveProfile.hoursPerDay;
                stats.omsorgsTimerBrukt =
                  stats.omsorgsDagerBrukt * userLeaveProfile.hoursPerDay;

                //  calculate used %
                stats.ferieTimerBruktProsent =
                  (stats.ferieTimerBrukt /
                    (userLeaveProfile.ferieDager *
                      userLeaveProfile.hoursPerDay)) *
                  100;
                stats.egenmeldingsTimerBruktProsent =
                  (stats.egenmeldingsTimerBrukt /
                    (userLeaveProfile.egenmeldingsDager *
                      userLeaveProfile.hoursPerDay)) *
                  100;
                stats.omsorgsTimerBruktProsent =
                  (stats.omsorgsTimerBrukt /
                    (userLeaveProfile.omsorgsDager *
                      userLeaveProfile.hoursPerDay)) *
                  100;
              }

              //console.log("returned stats:", stats);
              return stats;
            });
        });
      });
  }

  /*
  // not in use
  userGetYearStats = (uid, year) => {
    if (!year) {
      year = new Date().getFullYear();
      console.log("userGetYearStats() - use default year: " + year);
    }

    var usedDays = 0;

    // get approved requests for user for year
    var stats = this.leaveRequests()
      .where("userUid", "==", uid)
      .where("approvedStatus", "==", "approved")
      .where("dateStart", ">=", new Date(year, 0, 1))
      .where("dateStart", "<", new Date(year, 11, 31))
      .get()
      .then(function(collection) {
        collection.forEach(function(doc) {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, " => ", doc.data().workDays);
          usedDays += doc.data().workDays;
          //console.log(doc.id, " => ", doc.data().workDays, " - usedDays: ", usedDays);
        });

        return {
          usedDays,
          year
        };
      })
      .catch(function(error) {
        console.log("Error getting documents: ", error);
      });

    return stats;
  };
*/
}

export default StatisticsService;
