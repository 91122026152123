import React, { Component } from 'react';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import { withAuthentication } from '../Session';

const INITIAL_STATE = {
  name: '',
  ferieDager: 0,
  egenmeldingsDager: 0,
  omsorgsDager: 0,
  hoursPerDay: 7.5,
  isDefault: false,
};

class AddLeaveProfileFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onSubmit = (event) => {
    const {
      name,
      ferieDager,
      hoursPerDay,
      egenmeldingsDager,
      omsorgsDager,
      isDefault,
    } = this.state;

    this.props.firebase
      .leaveProfileAdd({
        name,
        ferieDager: parseInt(ferieDager),
        egenmeldingsDager: parseInt(egenmeldingsDager),
        omsorgsDager: parseInt(omsorgsDager),
        hoursPerDay: parseFloat(hoursPerDay),
        isDefault,
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onCheckboxChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const {
      name,
      ferieDager,
      egenmeldingsDager,
      omsorgsDager,
      hoursPerDay,
    } = this.state;

    return (
      <>
        <div className="offset-sm-2">
          <h4>Ny Fraværsprofil</h4>
        </div>
        <form onSubmit={this.onSubmit}>
          <div className="form-group row">
            <label
              htmlFor="inputName"
              className="col-sm-2 col-form-label text-right"
            >
              Navn:
            </label>
            <div className="col-sm-6">
              <input
                type="text"
                name="name"
                id="inputName"
                placeholder="Navn..."
                className="form-control"
                value={name}
                onChange={this.onChange}
                required
              ></input>
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="inputFeriedager"
              className="col-sm-2 col-form-label text-right"
            >
              Feriedager:
            </label>
            <div className="col-sm-2">
              <input
                type="number"
                name="ferieDager"
                id="inputFeriedager"
                className="form-control"
                value={ferieDager}
                onChange={this.onChange}
              ></input>
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="inputEgenmeldingsdager"
              className="col-sm-2 col-form-label text-right"
            >
              Egenmeldingsdager:
            </label>
            <div className="col-sm-2">
              <input
                type="number"
                name="egenmeldingsDager"
                id="inputEgenmeldingsdager"
                className="form-control"
                value={egenmeldingsDager}
                onChange={this.onChange}
              ></input>
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="inputOmsorgsdager"
              className="col-sm-2 col-form-label text-right"
            >
              Omsorgsdager:
            </label>
            <div className="col-sm-2">
              <input
                type="number"
                name="omsorgsDager"
                id="inputOmsorgsdager"
                className="form-control"
                value={omsorgsDager}
                onChange={this.onChange}
              ></input>
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="inputHoursPerDay"
              className="col-sm-2 col-form-label text-right"
            >
              Antall timer per dag:
            </label>
            <div className="col-sm-2">
              <input
                type="number"
                step="0.5"
                name="hoursPerDay"
                id="inputHoursPerDay"
                className="form-control"
                value={hoursPerDay}
                onChange={this.onChange}
              ></input>
            </div>
          </div>

          <div className="offset-sm-2">
            <button type="submit" className="btn btn-success  mb-2">
              LEGG TIL <i className="fas fa-plus-circle"></i>
            </button>
          </div>
        </form>
      </>
    );
  }
}

const AddLeaveProfileForm = compose(
  withAuthentication,
  withFirebase
)(AddLeaveProfileFormBase);

export default compose(
  //withAuthorization(condition),
  withFirebase
)(AddLeaveProfileForm);
