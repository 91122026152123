import React from 'react';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';

import NewLeaveRequestForm from './NewLeaveRequestForm';

const LeaveRequestPage = () => (
  <div>
    <div className="col-md-12">
      <h3>Meld ferie eller fravær</h3>
      <hr />
    </div>
    <div className="col-md-12">
      <NewLeaveRequestForm />
    </div>
  </div>
);

const condition = (authUser) => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase
)(LeaveRequestPage);
