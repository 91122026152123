import React, { Component } from 'react';
import { compose } from 'recompose';
import { withAuthorization, isAdmin } from '../Session';
import { withFirebase } from '../Firebase';

const INITIAL_STATE = {
  name: '',
};

class EditDepartmentPage extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onSubmit = (event) => {
    const { name } = this.state;

    this.props.firebase
      .departmentAdd({
        name,
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { name } = this.state;

    return (
      <>
        <div className="offset-sm-2">
          <h4>NY AVDELING</h4>
        </div>
        <form onSubmit={this.onSubmit}>
          <div className="form-group row">
            <label
              htmlFor="inputName"
              className="col-sm-2 col-form-label text-right"
            >
              Navn:
            </label>
            <div className="col-sm-6">
              <input
                type="text"
                name="name"
                id="inputName"
                placeholder="Navn..."
                className="form-control"
                value={name}
                onChange={this.onChange}
                required
              ></input>
            </div>
          </div>
          <div className="offset-sm-2">
            <button type="submit" className="btn btn-success  mb-2">
              LEGG TIL <i className="fas fa-plus-circle"></i>
            </button>
          </div>
        </form>
      </>
    );
  }
}

export default compose(
  withAuthorization(isAdmin),
  withFirebase
)(EditDepartmentPage);
