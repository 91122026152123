import React from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, isAdmin } from '../Session';
import AddLeaveProfileForm from './AddLeaveProfileForm';
import LeaveProfileList from './LeaveProfileList';

const LeaveProfilesPage = () => (
  <div>
    <h3>Fraværsprofiler</h3>

    <LeaveProfileList />
    <hr className="mb-5" />

    <AddLeaveProfileForm />
  </div>
);

export default compose(
  withAuthorization(isAdmin),
  withFirebase
)(LeaveProfilesPage);
