import * as ROLES from '../../constants/roles';

class FirestoreUserApi {
  constructor(firestore, fieldValue) {
    this.firestore = firestore;
    // https://firebase.google.com/docs/reference/js/firebase.firestore.FieldValue#servertimestamp
    this.fieldValue = fieldValue;
  }

  assignAdminRole(userId) {
    this.firestore
      .collection('users')
      .doc(userId)
      .set(
        {
          roles: {
            ADMIN: ROLES.ADMIN,
          },
        },
        { merge: true }
      );
  }

  assignAdminEmailRole(userId) {
    this.firestore
      .collection('users')
      .doc(userId)
      .set(
        {
          roles: {
            ADMIN_EMAIL: ROLES.ADMIN_EMAIL,
          },
        },
        { merge: true }
      );
  }

  removeAdminRole(userId) {
    return this.firestore
      .collection('users')
      .doc(userId)
      .update({
        roles: {
          USER: ROLES.USER,
        },
      });
  }

  removeAdminEmailRole(userId) {
    return this.firestore
      .collection('users')
      .doc(userId)
      .update({
        roles: {
          USER: ROLES.USER,
          ADMIN: ROLES.ADMIN,
        },
      });
  }

  getUserRef(userId) {
    return this.firestore.doc(`users/${userId}`);
  }

  getUsersRefs() {
    return this.firestore.collection('users').orderBy("displayName");
  }

  getUser(userId) {
    return this.getUserRef(userId)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          return doc.data();
        } else {
          console.log('User doc not found: ', userId);
          return null;
        }
      })
      .catch(function (error) {
        console.log('Error getting document:', error);
      });
  }

  getUsers() {
    return this.getUsersRefs()
      .get()
      .then(function (docs) {
        const users = [];
        docs.forEach((user) => {
          users.push(user.data());
        });
        return users;
      })
      .catch(function (error) {
        console.log('Error getting document:', error);
      });
  }

  updateUser(userId, data) {
    return this.firestore
      .collection('users')
      .doc(userId)
      .set(
        {
          ...data,
          updated: this.fieldValue.serverTimestamp(),
        },
        { merge: true }
      );
  }

  createUser(socialAuthUser, defaultLeaveProfile) {
    const data = {
      email: socialAuthUser.user.email,
      photoURL: socialAuthUser.user.photoURL,
      displayName: socialAuthUser.user.displayName,
      uid: socialAuthUser.user.uid,
      roles: {
        USER: 'USER',
      },
      lastLogin: new Date(),
      leaveProfileUid: defaultLeaveProfile ? defaultLeaveProfile.uid : '',
    };
    const options = { merge: true };
    return this.getUserRef(socialAuthUser.user.uid).set(data, options);
  }
}

export default FirestoreUserApi;
